const url = '/api/v2/client/'


export const ClientApiUrls = {
    // get users
    async getUsers(params) {
        return (await window.axios.get(url+'client-list/', { params: params })).data;
    },
};
  
