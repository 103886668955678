<template>
  <!-- в 4 степе линия оплаты -->
  <v-container>
    <v-row>
      <v-col cols="6" md="2">
        <v-text-field
          v-model.number="payment.value"
          label="Сумма"
          :disabled="payment && payment.id > 0 && payment.id != null"
          :rules="[() => !!payment.quantity]"
        />
      </v-col>
      <v-col cols="6" md="2">
        <v-select
          v-model="payment.payment_type"
          label="Тип"
          :items="items"
          :disabled="payment && payment.id > 0 && payment.id != null"
          :rules="[() => !!payment.payment_type]"
        />
      </v-col>
      <v-col cols="6" md="2">
        <v-select
          v-model="payment.payment_method"
          label="Вид оплаты"
          item-value="id"
          item-text="name"
          :items="getPaymentTypes()"
          :disabled="payment && payment.id > 0 && payment.id != null"
          :rules="[() => !!payment.payment_method]"
        />
      </v-col>
      <v-col cols="6" md="2">
        <v-text-field
          v-model="payment.comment"
          label="Комментарии"
          :disabled="payment && payment.id > 0 && payment.id != null"
          :rules="[() => !!payment.comment]"
        />
      </v-col>
      <v-col
        cols="6"
        md="2"
        v-if="payment && payment.id > 0 && payment.id != null"
      >
        <v-text-field
          :value="getCreatedDate()"
          label="Дата оплаты"
          :disabled="payment && payment.id > 0 && payment.id != null"
          :rules="[() => !!payment.comment]"
        />
      </v-col>
      <v-col cols="6" md="1">
        <v-btn
          v-if="
            payment &&
              payment.id > 0 &&
              payment.id != null &&
              payment.payment_type == 'payment'
          "
          class="mb-3"
          tile
          outlined
          color="primary"
          @click="exportPdf(payment)"
          :disabled="loadingPdf"
          :loading="loadingPdf"
        >
          Приходник PDF
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        md="2"
        v-if="!payment.id"
        style="display: flex; justify-content: center; align-items: center"
      >
        <v-btn
          tile
          small
          dense
          outlined
          :disabled="my_btn_disabled"
          color="warning"
          @click="
            my_btn_disabled = true;
            save(index);
          "
        >
          <v-icon left> mdi-plus </v-icon>
          Сохранить
        </v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "Payment",
  props: {
    payment: Object,
    remove: Function,
    canPay: Boolean,
    save: Function,
    index: Number,
    disabledBtn: { type: Boolean, default: false }
  },
  data() {
    let items = [
      { text: "Возврат", value: "return" },
      { text: "Платеж", value: "payment" }
    ];
    if (this.payment && this.payment.id > 0 && this.payment.id != null) {
      items.push({
        text: "Платеж",
        value: "payment"
      });
    }
    return {
      items,
      fromMenu: false,
      my_btn_disabled: false,
      loadingPdf: false
    };
  },
  computed: {
    ...mapGetters(["getPaymentTypes"])
  },
  methods: {
    getCreatedDate() {
      return moment(Number(this.payment.created_at) * 1000).format(
        "DD.MM.YYYY HH:mm"
      );
    },
    async exportPdf(payment) {
      try {
        this.loadingPdf = true;
        let resp = (
          await window.axios.get(`/api/v2/booking/parishioner/${payment.id}/`)
        ).data;
        var link = document.createElement("a");
        link.download = "prihodnik.pdf";
        link.target = "_blank";
        let ticket = resp.prihodnik;
        if (ticket && !ticket.includes("https")) {
          ticket = ticket.replace("http", "https");
        }
        link.href = ticket;
        console.log(link.href);
        document.body.appendChild(link);
        setTimeout(function() {
          link.click();
          document.body.removeChild(link);
        }, 500);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingPdf = false;
      }
    }
  }
};
</script>

<style scoped></style>
