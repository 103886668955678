<template>
  <!-- форма резерва -->
  <v-container>
    <v-row>
      <v-col> Новое бронирование </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs v-if="booking_time == 'all'" v-model="type">
          <v-tab value="fix">Фиксированное время</v-tab>
          <v-tab @click="convertType" value="not_fix">Нефиксированное время</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="indatemenu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="from"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="comInDateFormatted"
                  label="Заезд*:"
                  readonly
                  v-bind="attrs"
                  prepend-icon="mdi-calendar-blank-multiple"
                  v-on="on"
                />
              </template>
              <v-date-picker
                :first-day-of-week="1"
                v-model="from"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn text color="primary" @click="menu = false">
                  Отмена
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.indatemenu.save(from), handleChange()"
                >
                  Применить
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="timeCheckin"
              dense
              label="Время заезда"
              type="time"
							:ampm="false"
              style="padding-top: 15px; margin-top: 4px"
              @change="checkTime('in', timeCheckin)"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="type == 'fix'">
            <v-checkbox
              @change="checkStatus('early', is_early)"
              v-model="is_early"
              :key="reRender"
              label="Ранний заезд"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="outDateMenu"
              v-model="menu3"
              :close-on-content-click="false"
              :return-value.sync="to"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="comOutDateFormatted"
                  label="Выезд*:"
                  readonly
                  v-bind="attrs"
                  prepend-icon="mdi-calendar-blank-multiple"
                  v-on="on"
                />
              </template>
              <v-date-picker
                :first-day-of-week="1"
                v-model="to"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn text color="primary" @click="menu3 = false">
                  Отмена
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.outDateMenu.save(to),
                      handleChange(),
                      checkTime('out', timeCheckout)
                  "
                >
                  Применить
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="timeCheckout"
              dense
              label="Время выезда"
              type="time"
							:ampm="false"
              style="padding-top: 15px; margin-top: 4px"
              @change="checkTime('out', timeCheckout)"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-checkbox
              @change="checkStatus('late', is_late)"
              v-model="is_late"
              :key="reRender"
              label="Поздний выезд"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="pa-0 ma-0">
        <v-checkbox
          v-model="is_group"
          class="ma-0 pa-0"
          label="Групповое бронирование"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <v-btn
          style="width:100%"
          color="primary"
          @click="reserve_from_chess"
          :disabled="disabledBtn"
        >
          Резерв
        </v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          style="width:100%"
          color="primary"
          @click="closeRepair"
          :disabled="disabledBtn"
        >
          Закрыть на ремонт
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import user from "../../../store/auth/index";
import toast from "../../../plugins/toast";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js";

export default {
  name: "QuestionReserve",
  data() {
    return {
      is_group: false,
      disabledBtn: false,
      reRender: 0,
      timeCheckin: user.getters.getCheckInTime,
      timeCheckout: user.getters.getCheckOutTime,
      menu: false,
      menu3: false,
      is_early: false,
      is_late: false,
      comInDateFormatted: null,
      from: null,
      comOutDateFormatted: null,
      to: null,
      type: "fix",
      booking_time: localStorage.getItem("booking_time")
    };
  },
  watch: {
    "$store.state.bookingModal": function() {
      // если заново откроется этот компонент, все пересобрать
      this.timeCheckin = user.getters.getCheckInTime;
      this.timeCheckout = user.getters.getCheckOutTime;
      this.is_early = false;
      this.is_late = false;
      this.from = this.$store.state.template.from;
      this.to = this.$store.state.template.to;
      this.type = 'fix'
      console.log(this.type);
      this.comInDateFormatted = moment(this.$store.state.template.from).format(
        "DD.MM.YYYY"
      );
      this.comOutDateFormatted = moment(this.$store.state.template.to).format(
        "DD.MM.YYYY"
      );
    }
  },
  created() {
    this.comInDateFormatted = moment(this.$store.state.template.from).format(
      "DD.MM.YYYY"
    );
    this.comOutDateFormatted = moment(this.$store.state.template.to).format(
      "DD.MM.YYYY"
    );
    this.from = this.$store.state.template.from;
    this.to = this.$store.state.template.to;
    if (this.booking_time == "full") {
      this.type = "fix";
    } else if (this.booking_time == "half") {
      setTimeout(() => {
        this.convertType();
      }, 0);
    }
  },
  methods: {
    // нефиксированное время
    convertType() {
      this.type = "not_fix";
      // this.to = moment(this.from)
      //   .add(1, "days")
      //   .format("YYYY-MM-DD");
      // this.$refs.outDateMenu.save(this.to);
      this.handleChange();
      this.timeCheckout = this.timeCheckin;
      this.checkTime("out", this.timeCheckout);
      this.checkStatus("late", this.is_late);
    },
    // триггер когда изменится дата
    handleChange() {
      this.$store.commit("SET_BOOKING_FROM", {
        inDate: this.from,
        outDate: this.to
      });
      this.comInDateFormatted = moment(this.$store.state.template.from).format(
        "DD.MM.YYYY"
      );
      this.comOutDateFormatted = moment(this.$store.state.template.to).format(
        "DD.MM.YYYY"
      );
      this.from = this.$store.state.template.from;
      this.to = this.$store.state.template.to;
    },
    // проверка когда checkbox [is_late, is_late] = { true | false }
    checkStatus(type, val) {
      if (type == "early" && val == true) {
        if (`${this.timeCheckin}` == localStorage.getItem("check_in_time")) {
          toast.error("Укажите время раннего заезда");
          this.is_early = false;
        } else if (
          `${this.timeCheckin}` > localStorage.getItem("check_in_time")
        ) {
          toast.error(
            `Время заезда должно быть меньше чем ${localStorage
              .getItem("check_in_time")
              .substring(0, 5)}`
          );
          this.is_early = false;
        } else {
          this.is_early = val;
        }
      } else if (type == "early" && val == false) {
        if (`${this.timeCheckin}` < localStorage.getItem("check_in_time")) {
          toast.error(
            `Время заезда не должно быть меньше чем ${localStorage
              .getItem("check_in_time")
              .substring(0, 5)}`
          );
          this.is_early = true;
        } else {
          this.is_early = val;
        }
      }
      if (type == "late" && val == true && this.type != "not_fix") {
        if (`${this.timeCheckout}` < localStorage.getItem("check_out_time")) {
          toast.error(
            `Время выезда должно быть больше чем ${localStorage
              .getItem("check_out_time")
              .substring(0, 5)}`
          );
          this.is_late = false;
        } else if (
          `${this.timeCheckout}` == localStorage.getItem("check_out_time")
        ) {
          toast.error("Укажите время позднего выезда");
          this.is_late = false;
        } else {
          this.is_late = val;
        }
      } else if (type == "late" && val == false && this.type != "not_fix") {
        if (`${this.timeCheckout}` > localStorage.getItem("check_out_time")) {
          toast.error(
            `Время выезда не должно быть больше чем ${localStorage
              .getItem("check_out_time")
              .substring(0, 5)}`
          );
          this.is_late = true;
        } else {
          this.is_late = val;
        }
      } else {
        var duration = moment.duration(
          moment(`${this.to} ${this.timeCheckout}`).diff(
            moment(`${this.from} ${this.timeCheckin}`)
          )
        );
        var hours = duration.asHours();
        if (hours > 24 && hours % 24 != 0) {
          this.is_late = true;
        } else {
          this.is_late = false;
        }
      }
      this.reRender++;
    },
    // проверка когда checkbox [is_late, is_late] = { true | false }
    checkTime(type, time) {
      if (
        type == "in" &&
        `${time}:00` >= localStorage.getItem("check_in_time") &&
        this.is_early == true
      ) {
        this.is_early = false;
      }
      if (
        type == "in" &&
        `${time}:00` < localStorage.getItem("check_in_time")
      ) {
        this.is_early = true;
      }
      if (
        type == "out" &&
        `${time}:00` >= localStorage.getItem("check_out_time") &&
        this.is_late == true
      ) {
        this.is_late = false;
      }
      if (
        type == "out" &&
        `${time}:00` > localStorage.getItem("check_out_time")
      ) {
        this.is_late = true;
      }
      if (type === "in" && this.type === "not_fix") {
        // if (this.from === this.to) {
        //   this.to = moment(this.to)
        //     .add(1, "days")
        //     .format("YYYY-MM-DD");
        //   this.$refs.outDateMenu.save(this.to);
        // }
        let start = moment(`${this.from} ${this.timeCheckin}`)
          .add(24, "hours")
          .format("YYYY-MM-DD HH:mm");
        this.timeCheckout = start.substring(11);
        this.checkTime("out", this.timeCheckout);
        this.checkStatus("late", this.is_late);
        this.handleChange();
      }
      if (type == "out" && this.type === "not_fix") {
        var duration = moment.duration(
          moment(`${this.to} ${this.timeCheckout}`).diff(
            moment(`${this.from} ${this.timeCheckin}`)
          )
        );
        var hours = duration.asHours();
        if (hours > 24 && hours % 24 != 0) {
          this.is_late = true;
        } else {
          this.is_late = false;
        }
      }
      this.reRender++;
    },
    // закрыть на ремонт функция
    async closeRepair() {
      this.disabledBtn = true;
      let from = this.comInDateFormatted;
      let to = this.comOutDateFormatted;
      let compared_in_times =
        new Date("1/1/1999 " + this.timeCheckin) >=
        new Date("1/1/1999 " + user.getters.getCheckInTime);
      let compared_out_times =
        new Date("1/1/1999 " + this.timeCheckout) <=
        new Date("1/1/1999 " + user.getters.getCheckOutTime);
      if (this.is_early && compared_in_times) {
        toast.error("Вы выбрали ранний заезд, но время не раньше");
        return;
      }
      if (this.is_late && compared_out_times) {
        toast.error("Вы выбрали поздний выезд, но время не позже");
        return;
      }
      from = moment(from, "DD.MM.YYYY").format("YYYY-MM-DD");
      to = moment(to, "DD.MM.YYYY").format("YYYY-MM-DD");

      let body = {
        room: this.$store.state.template.room,
        check_in: `${from} ${this.timeCheckin}`,
        check_out: `${to} ${this.timeCheckout}`
      };
      try {
        await CategoriesApiUrls.repairRoom(body);
        this.$store.commit("CLOSE_REPAIR"); // закрыть на ремонт
        toast.success("Закрыто на ремонт");
        this.$emit("outClick");
      } finally {
        this.disabledBtn = false;
      }
    },
    // сделать резерв
    async reserve_from_chess() {
      let from = this.comInDateFormatted;
      let to = this.comOutDateFormatted;
      let compared_in_times =
        new Date("1/1/1999 " + this.timeCheckin) >=
        new Date("1/1/1999 " + user.getters.getCheckInTime);
      let compared_out_times =
        new Date("1/1/1999 " + this.timeCheckout) <=
        new Date("1/1/1999 " + user.getters.getCheckOutTime);
      if (this.is_early && compared_in_times) {
        toast.error("Вы выбрали ранний заезд, но время не раньше");
        return;
      }
      if (this.is_late && compared_out_times) {
        toast.error("Вы выбрали поздний выезд, но время не позже");
        return;
      }
      from = moment(from, "DD.MM.YYYY").format("YYYY-MM-DD");
      to = moment(to, "DD.MM.YYYY").format("YYYY-MM-DD");
      this.disabledBtn = true;
      let body = {
        check_in: `${from} ${this.timeCheckin}`,
        check_out: `${to} ${this.timeCheckout}`,
        room: this.$store.state.template.room,
        is_time: false,
        is_group: this.is_group,
        no_time: this.type === "not_fix"
      };
      try {
        let resp = await BookingApiUrls.reserveBooking(body);
        this.disabledBtn = false;
        this.$store.commit("SET_STEADY", resp.data); // отправляем инфу в store
        this.$store.commit("SET_CHECK_DELETE", true); // если бронь сразу закроется, то удалить ее нужно
        this.$store.commit("OPEN_CLOSE_RESERVE", { status: "new" }); // открывает форму бронирования
        this.$store.commit("TO_TAB", "2"); // переходит на 2 вкладку
        this.is_group = false;
      } catch (e) {
        this.disabledBtn = false;
      }
    }
  }
};
</script>

<style scoped></style>
