<template>
  <div>
    <div v-for="(task, index) in getSteady().task_list" :key="index">
      <v-row>
        <v-col cols="6" md="4">
          <v-text-field v-model="task.title" label="Что сделать?" />
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            item-text="full_name"
            item-value="id"
            label="Ответственный за бронь*:"
            :items="users"
            v-model="task.responsible"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-menu
            :ref="index + 'menu'"
            v-model="task.menu"
            :close-on-content-click="false"
            :return-value.sync="task.todo_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(task.todo_date)"
                label="Дата выполнения"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="task.todo_date"
              no-title
              scrollable
              :first-day-of-week="1"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="task.menu = false">
                Отмена
              </v-btn>
              <v-btn text color="primary" @click="saveDate(index, task)">
                Применить
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            @change="handleChange"
            v-model="task.todo_time"
            label="Время выполнения"
            type="time"
						:ampm="false"
          />
        </v-col>
      </v-row>
      <v-col cols="12 pl-0" md="8">
        <v-textarea
          v-model="task.description"
          outlined
          name="input-7-4"
          @change="handleChange"
          label="Описание:"
        />
      </v-col>
      <v-btn
        v-if="getSteady().task_list.length != 1"
        class="mb-5"
        tile
        outlined
        color="red lighten-1"
        @click="deleteTask(index)"
      >
        <v-icon left> mdi-minus </v-icon>
        Удалить задачу
      </v-btn>
      <v-divider class="mb-2" />
    </div>
    <v-row class="pl-3 pt-4">
      <v-btn class="mb-5" tile outlined color="primary" @click="addTask">
        <v-icon left> mdi-plus </v-icon>
        Добавить задачу
      </v-btn>
      <v-btn
        class="mb-5 ml-3"
        tile
        outlined
        color="red lighten-1"
        @click="clearTasks"
      >
        <v-icon left> mdi-minus </v-icon>
        Очистить задачи
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { UsersApiUrls } from "@/services/accommodationRequests/user.api.js";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";

export default {
  name: "Step5",
  data() {
    return {
      tasks_arr: [],
      users: []
    };
  },
  computed: {
    ...mapGetters(["getSteady"])
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    async getAllUsers() {
      let resp = await UsersApiUrls.getUsers();
      resp.forEach(element => {
        element.full_name = `${element.first_name || ""} ${element.last_name ||
          ""} ${element.middle_name || ""}`;
      });
      this.users = resp;
    },
    handleChange() {
      this.$store.commit("SET_UPDATED_STEADY", 1);
    },
    formatDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
    async clearTasks() {
      this.handleChange();
      if (this.getSteady().task_list.length > 0) {
        let arr = [];
        this.getSteady().task_list.forEach(e => {
          if (e.id) arr.push(e.id);
        });
        if (arr.length > 0) {
          let body = {
            booking: this.getSteady().is_group
              ? this.getSteady().group_parent
              : this.getSteady().id,
            tasks: arr
          };
          await BookingApiUrls.deleteAllTasks(body);
        }
        while (this.getSteady().task_list.length > 0) {
          this.getSteady().task_list.pop();
        }
      }
    },
    saveDate(index, task) {
      this.handleChange();
      this.$refs[`${index}menu`][0].save(task.todo_date);
    },
    addTask() {
      this.handleChange();
      this.getSteady().task_list = this.getSteady().task_list || [];
      this.getSteady().task_list.push({
        todo_date: null,
        description: null,
        todo_time: "12:00",
        title: null
      });
    },
    deleteTask(index) {
      this.handleChange();
      this.getSteady().task_list.splice(index, 1);
    }
  }
};
</script>

<style scoped></style>
