<template>
  <div>
    <v-data-table :headers="headers" :items="services">
      <template v-slot:[`item.type`]="{ item }">
        {{ typeList[item.type] }}
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
        <v-text-field
          type="number"
          v-model.number="item.quantity"
          @input="calculateTotal(item)"
          @change="changeTableValue(item), handleChange()"
          @keydown="preventSigns"
          :min="0"
        />
      </template>
      <template v-slot:[`item.rooms`]="{ item }">
        <v-select
          v-model="item.rooms"
          multiple
          :items="room_list"
          item-value="id"
          item-text="name"
          label="Комнаты"
          @change="calculateTotal(item), changeTableValue(item), handleChange()"
        ></v-select>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";

export default {
  name: "Step3",
  props: {
    services: Array
  },
  data() {
    return {
      headers: [
        { text: "Услуга", value: "service", class: "table-title" },
        { text: "Цена", value: "price", class: "table-title" },
        { text: "Тип", value: "type", class: "table-title" },
        { text: "Количество", value: "quantity", class: "table-title" },
        { text: "К оплате", value: "total", class: "table-title" }
      ],
      quantity: 0,
      typeList: {
        once: "Единоразово",
        day: "Cутки"
      },
      room_list: []
    };
  },
  computed: {
    ...mapGetters(["getSteady"]),
    myEl() {
      return this.$store.getters.getEl();
    }
  },
  watch: {
    myEl() {
      if (this.myEl === 4) {
        if (this.getSteady().is_group) {
          this.getRooms();
          let here = this.headers.find(e => e.value == "rooms");
          if (!here) {
            this.headers.splice(4, 0, {
              text: "Комнаты",
              value: "rooms",
              class: "table-title"
            });
          }
        } else {
          this.headers = this.headers.filter(e => e.value != "rooms");
        }
      }
    }
  },
  methods: {
    async getRooms() {
      this.room_list = await BookingApiUrls.getGroupBookingRooms(
        this.getSteady().group_parent
      );
    },
    handleChange() {
      this.$store.commit("SET_UPDATED_STEADY", 1);
    },
    preventSigns(e) {
      if (e.key == "-" || e.key == "+") {
        e.preventDefault();
      }
    },
    calculateTotal(item) {
      if (item.type === "day") {
        // если тип день
        let days;
        if (this.getSteady().is_group) {
          // если групповая бронь
          item.total = 0;
          item.rooms.forEach(i => {
            this.getSteady().categories.forEach(j => {
              j.child_list.forEach(k => {
                if (k.id == i) {
                  days = Math.ceil(
                    (new Date(+k.check_out) - new Date(+k.check_in)) /
                      (60 * 60 * 24)
                  );
                  item.total += days * item.price * item.quantity;
                }
              });
            });
          });
        } else {
          // если простая бронь
          days = Math.ceil(
            (new Date(+this.getSteady().check_out) -
              new Date(+this.getSteady().check_in)) /
              (60 * 60 * 24)
          );
          item.total = item.price * item.quantity * days;
        }
      } else {
        // если тип единоразово
        item.total = this.getSteady().is_group
          ? item.price * item.quantity * item.rooms.length
          : item.price * item.quantity;
      }
    },
    changeTableValue(item) {
      let str = this.getSteady().is_group
        ? "group_calculation"
        : "calculated_by";
      if (
        this.getSteady()[str].services &&
        this.getSteady()[str].services.length > 0
      ) {
        // удаляю из getSteady().services
        for (let i = 0; i < this.getSteady()[str].services.length; i++) {
          if (this.getSteady()[str].services[i].service == item.id) {
            this.getSteady()[str].services.splice(i, 1);
          }
        }
      }
      const needService = {
        quantity: Math.abs(item.quantity),
        booking: this.getSteady().id,
        service: item.id
      };
      if (this.getSteady().is_group) needService.rooms = item.rooms;
      if (this.getSteady()[str] && this.getSteady()[str].services) {
        let isHere = false;

        this.getSteady()[str].services.forEach(e => {
          if (needService.service == e.id || needService.service == e.service) {
            isHere = true;
            e.quantity = needService.quantity;
          }
        });
        if (!isHere) {
          // если сервиса нет, то добавляю в getSteady().services
          this.getSteady()[str].services.push(needService);
        }
      }
      if (
        this.getSteady()[str] &&
        this.getSteady()[str].services &&
        this.getSteady()[str].services.length > 0
      ) {
        for (let i = 0; i < this.getSteady()[str].services.length; i++) {
          // тут я переформатирую quantity значение, если бы проект писался на ts, такого бы не было
          if (this.getSteady()[str].services[i].quantity == "") {
            this.getSteady()[str].services[i].quantity = 0;
          }
        }
      }
    }
  }
};
</script>

<style scoped></style>
