var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-text-field',{attrs:{"label":"Сумма","disabled":_vm.payment && _vm.payment.id > 0 && _vm.payment.id != null,"rules":[() => !!_vm.payment.quantity]},model:{value:(_vm.payment.value),callback:function ($$v) {_vm.$set(_vm.payment, "value", _vm._n($$v))},expression:"payment.value"}})],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-select',{attrs:{"label":"Тип","items":_vm.items,"disabled":_vm.payment && _vm.payment.id > 0 && _vm.payment.id != null,"rules":[() => !!_vm.payment.payment_type]},model:{value:(_vm.payment.payment_type),callback:function ($$v) {_vm.$set(_vm.payment, "payment_type", $$v)},expression:"payment.payment_type"}})],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-select',{attrs:{"label":"Вид оплаты","item-value":"id","item-text":"name","items":_vm.getPaymentTypes(),"disabled":_vm.payment && _vm.payment.id > 0 && _vm.payment.id != null,"rules":[() => !!_vm.payment.payment_method]},model:{value:(_vm.payment.payment_method),callback:function ($$v) {_vm.$set(_vm.payment, "payment_method", $$v)},expression:"payment.payment_method"}})],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-text-field',{attrs:{"label":"Комментарии","disabled":_vm.payment && _vm.payment.id > 0 && _vm.payment.id != null,"rules":[() => !!_vm.payment.comment]},model:{value:(_vm.payment.comment),callback:function ($$v) {_vm.$set(_vm.payment, "comment", $$v)},expression:"payment.comment"}})],1),(_vm.payment && _vm.payment.id > 0 && _vm.payment.id != null)?_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-text-field',{attrs:{"value":_vm.getCreatedDate(),"label":"Дата оплаты","disabled":_vm.payment && _vm.payment.id > 0 && _vm.payment.id != null,"rules":[() => !!_vm.payment.comment]}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","md":"1"}},[(
          _vm.payment &&
            _vm.payment.id > 0 &&
            _vm.payment.id != null &&
            _vm.payment.payment_type == 'payment'
        )?_c('v-btn',{staticClass:"mb-3",attrs:{"tile":"","outlined":"","color":"primary","disabled":_vm.loadingPdf,"loading":_vm.loadingPdf},on:{"click":function($event){return _vm.exportPdf(_vm.payment)}}},[_vm._v(" Приходник PDF ")]):_vm._e()],1),(!_vm.payment.id)?_c('v-col',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"cols":"6","md":"2"}},[_c('v-btn',{attrs:{"tile":"","small":"","dense":"","outlined":"","disabled":_vm.my_btn_disabled,"color":"warning"},on:{"click":function($event){_vm.my_btn_disabled = true;
          _vm.save(_vm.index);}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Сохранить ")],1)],1):_vm._e()],1),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }