<template>
  <v-row>
    <v-col cols="12">
      <v-tabs>
        <v-tab
          @click="selectCategory(tab.id)"
          v-for="tab in catregoryList"
          :key="tab.id"
          >{{ tab.name }}</v-tab
        >
      </v-tabs>
    </v-col>
    <v-col cols="12">
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Сегодня
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="500">
        <v-calendar
          weekdays="1, 2, 3, 4, 5, 6, 0"
          ref="calendar"
          :value="today"
          color="primary"
          v-model="focus"
        >
          <template v-slot:day-label="{ date }">
            <div
              class="single_date_rest"
              :class="{ todaysDate: date == today }"
            >
              {{ formatDate(date) }}
            </div>
          </template>
          <template v-slot:day="{ date }">
            <div
              @click="opendialog(date)"
              class="single_day"
              :class="{ disabledDay: !allowedDates(date) }"
            >
              <h3>{{ stocksPerDay(date) }}</h3>
            </div>
            <v-overlay
              :absolute="true"
              :opacity="0.7"
              :value="!stocksPerDay(date)"
            >
              <v-progress-circular
                :size="70"
                :width="2"
                color="amber"
                indeterminate
              />
            </v-overlay>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
    <v-overlay :absolute="true" :opacity="0.7" :value="overlay">
      <v-progress-circular :size="70" :width="7" color="amber" indeterminate />
    </v-overlay>
    <!-- нужен -->
    <!-- модалка при клике на дату в активном отдыхе -->
    <v-dialog v-model="bookingDialog" max-width="900">
      <v-card class="pa-4">
        <h2 style="text-align: center; margin-bottom: 16px">Бронирование</h2>
        <v-row v-if="!availableTariffs.length">
          <v-col cols="12">
            <h2>Нет тарифов</h2>
          </v-col>
        </v-row>
        <div>
          <v-row>
            <v-col cols="5">
              <v-select
                v-if="availableTariffs[0]"
                v-model="availableTariffs[0].enum"
                :items="selectItems"
                disabled
                style="height: 30px"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            v-for="(itemTime, index1) in availableTariffs"
            :key="itemTime.id"
          >
            <v-col cols="6" md="4" style="vertical-align: top">
              <v-row>
                <v-col>
                  <p class="text-h4 font-weight-light">
                    Осталось: {{ itemTime.stock_number }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    disabled
                    label="С"
                    v-model="itemTime.start_time"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    disabled
                    label="По"
                    v-model="itemTime.end_time"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="5">
              <v-row
                v-for="(itemPrice, index2) in itemTime.price_list"
                :key="itemPrice.id"
              >
                <v-col cols="7">
                  <p class="text-h4 font-weight-light">
                    {{ itemPrice.name }}: {{ itemPrice.price }} тг
                  </p>
                </v-col>
                <v-col cols="5">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    style="width: 30px; height: 30px"
                    color="primary"
                    :disabled="itemPrice.count == 0"
                    @click="clickCount(index1, index2, -1)"
                    :style="{ background: statusMinusColor(itemPrice) }"
                  >
                    <v-icon dark> mdi-minus </v-icon>
                  </v-btn>
                  <span v-text="itemPrice.count"></span>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    style="width: 30px; height: 30px"
                    color="primary"
                    :disabled="maxCount(itemTime)"
                    @click="clickCount(index1, index2, 1)"
                    :style="{ background: statusPlusColor(itemTime) }"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="3">
              <v-btn
                color="primary"
                class="dialog_buttons"
                @click="openBookingDialog(index1)"
                >Забронировать</v-btn
              >
              <v-btn
                color="primary"
                class="dialog_buttons"
                :disabled="disabledBtn"
                @click="reserveTariff(itemTime, index1, 'book')"
                >Продать</v-btn
              >
              <v-btn
                color="primary"
                class="dialog_buttons"
                @click="reserveTariff(itemTime, index1, 'stock')"
                :disabled="disabledBtn"
                >Уменьшить сток</v-btn
              >
            </v-col>
            <v-divider></v-divider>
          </v-row>
          <v-divider dark></v-divider>
        </div>
      </v-card>
    </v-dialog>
    <!-- нужен -->
    <!-- модалка бронирования с формой -->
    <v-dialog v-model="bookingFormDialog" max-width="1200">
      <v-card class="pa-4">
        <v-card-title>Внесите данные</v-card-title>
        <v-form ref="form">
          <v-row>
            <v-col cols="6" md="4">
              <v-text-field
                label="Имя"
                v-model="first_name"
                :rules="firstRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                label="Фамилия"
                v-model="last_name"
                :rules="lastRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                label="Отчество"
                v-model="middle_name"
                :rules="middleRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                label="Телефон"
                v-model="phone_number"
                :rules="phoneRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                label="Email"
                v-model="email"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                label="Паспорт"
                v-model="id_card"
                :rules="idRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <v-select
                label="Пол"
                :items="genders"
                v-model="gender"
                :rules="genderRules"
              ></v-select>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                label="Комментарии"
                v-model="comment"
                :rules="commentRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions
          ><v-btn @click="bookingFormDialog = false">закрыть</v-btn
          ><v-btn color="primary" @click="bookForm" :disabled="disabledBtn"
            >Забронировать</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import toast from "../../plugins/toast";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";
import { LeisureCategoriesApiUrls } from "@/services/leisureRequests/categories.api.js";
import { CalendarApiUrls } from "@/services/accommodationRequests/calendar.api.js";
import { CompaniesApiUrls } from "@/services/accommodationRequests/companies.api.js";

export default {
  data: () => ({
    disabledBtn: false,
    first_name: "",
    last_name: "",
    middle_name: "",
    phone_number: "",
    email: "",
    id_card: "",
    gender: "male",
    comment: "",
    genders: [
      {
        text: "Жен.",
        value: "female"
      },
      {
        text: "Муж.",
        value: "male"
      }
    ],
    bookingFormDialog: false,
    prices: [],
    client: {},
    selectItems: [
      {
        text: "Понедельник",
        value: "mon"
      },
      {
        text: "Вторник",
        value: "tue"
      },
      {
        text: "Среда",
        value: "wed"
      },
      {
        text: "Четверг",
        value: "thu"
      },
      {
        text: "Пятница",
        value: "fri"
      },
      {
        text: "Суббота",
        value: "sat"
      },
      {
        text: "Воскресенье",
        value: "san"
      },
      {
        text: "Январь",
        value: "jan"
      },
      {
        text: "Февраль",
        value: "feb"
      },
      {
        text: "Март",
        value: "mar"
      },
      {
        text: "Апрель",
        value: "apr"
      },
      {
        text: "Май",
        value: "may"
      },
      {
        text: "Июнь",
        value: "jun"
      },
      {
        text: "Июль",
        value: "jul"
      },
      {
        text: "Август",
        value: "aug"
      },
      {
        text: "Сентябрь",
        value: "sep"
      },
      {
        text: "Октябрь",
        value: "okt"
      },
      {
        text: "Ноябрь",
        value: "nov"
      },
      {
        text: "Декабрь",
        value: "dec"
      }
    ],
    required_fields: [], // каждая компания может содержать различные required fields для брони
    bookingDialog: false,
    dialog: false,
    overlay: false,
    stocksList: [],
    catregoryList: [],
    focus: "",
    today: new Date().toISOString().substr(0, 10),
    stockModel: {},
    availableTariffs: []
  }),
  computed: {
    // валидация
    firstRules() {
      return [
        v => {
          if (this.required_fields.includes("first_name"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ];
    },
    lastRules() {
      return [
        v => {
          if (this.required_fields.includes("last_name"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ];
    },
    middleRules() {
      return [
        v => {
          if (this.required_fields.includes("middle_name"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ];
    },
    phoneRules() {
      return [
        v => {
          if (this.required_fields.includes("phone_number"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ];
    },
    emailRules() {
      return [
        v => {
          if (this.required_fields.includes("email"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ];
    },
    idRules() {
      return [
        v => {
          if (this.required_fields.includes("document_number"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ];
    },
    genderRules() {
      return [
        v => {
          if (this.required_fields.includes("gender"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ];
    },
    commentRules() {
      return [
        v => {
          if (this.required_fields.includes("comment"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ];
    }
  },
  created() {
    if (localStorage.getItem("object_type") == "is_leisure") {
      this.getCategories();
      this.loadRequiredFields();
    }
  },
  methods: {
    async bookForm() {
      // забронировать с формой
      if (!this.$refs.form.validate()) return;
      this.disabledBtn = true;
      let body = {
        booking_status: "book",
        client: {
          last_name: this.last_name,
          first_name: this.first_name,
          middle_name: this.middle_name,
          phone_number: this.phone_number,
          email: this.email,
          gender: this.gender
        },
        category: this.stockModel.category,
        check_in: this.stockModel.date,
        prices: this.prices,
        comment: this.comment
      };

      try {
        await BookingApiUrls.createLeisureBook(body);
        toast.success("Успешно забронировано!");
        this.bookingFormDialog = false;
        this.bookingDialog = false;
        this.getCategories();
      } finally {
        this.disabledBtn = false;
      }
    },
    async loadRequiredFields() {
      // загрузить required fields
      let response = await CompaniesApiUrls.getInfo();
      for (const [key, value] of Object.entries(response.required_fields)) {
        this.required_fields.push(value.name);
      }
      if (this.required_fields.length == 0) {
        this.required_fields = ["first_name", "phone_number"];
      }
    },
    async reserveTariff(itemTime, index1, status) {
      // резерв без формы
      this.disabledBtn = true;
      this.prices = [];
      this.availableTariffs[index1].price_list.forEach(price => {
        if (price.count > 0) {
          this.prices.push({
            price: price.id,
            quantity: price.count
          });
        }
      });
      if (this.prices.length == 0) {
        toast.error("Выберите количество!");
        this.disabledBtn = false;
        return;
      }

      let body = {
        booking_status: status,
        client: null,
        category: this.stockModel.category,
        check_in: this.stockModel.date,
        prices: this.prices,
        comment: ""
      };

      try {
        await BookingApiUrls.createLeisureBook(body);
        const word =
          status == "stock"
            ? "Успешно уменьшен сток!"
            : "Успешно зарезервированно!";
        toast.success(word);
        this.getCategories();
        this.bookingFormDialog = false;
        this.bookingDialog = false;
      } finally {
        this.disabledBtn = false;
      }
    },
    openBookingDialog(index) {
      // открыть модалку с формой для бронирования
      this.prices = [];
      this.availableTariffs[index].price_list.forEach(price => {
        if (price.count > 0) {
          this.prices.push({
            price: price.id,
            quantity: price.count
          });
        }
      });
      if (this.prices.length == 0) {
        toast.error("Выберите количество!");
        return;
      }
      this.bookingFormDialog = true;
    },
    statusMinusColor(itemPrice) {
      // проверка на цвет кнопки минус
      if (itemPrice.count == 0) {
        return "lightgrey !important";
      }
    },
    statusPlusColor(itemTime) {
      // проверка на цвет кнопки плюс
      if (this.maxCount(itemTime)) {
        return "lightgrey !important";
      }
    },
    maxCount(itemTime) {
      // проверка количества count, которые выбрали и stock_number допустимного значения
      const count = itemTime.price_list.reduce((prev, cur) => {
        return prev + cur.count;
      }, 0);
      if (count >= itemTime.stock_number) return true;
      return false;
    },
    clickCount(index1, index2, a) {
      // добавить или уменьшить количество мест
      this.availableTariffs[index1].price_list[index2].count += a;
      let price = this.availableTariffs[index1].price_list[index2];
      this.availableTariffs[index1].price_list.splice(index2, 1, price);
    },
    allowedDates: val => val >= new Date().toISOString().substr(0, 10),
    async getTariffListV2() {
      // загрузить тарифы определенного дня
      try {
        this.availableTariffs = await CalendarApiUrls.getLeisurePrices({
          category_id: this.stockModel.category,
          start: new Date(this.stockModel.date).getTime() / 1000
        });
        this.availableTariffs.forEach(tariff => {
          tariff.price_list.forEach(price => {
            price.count = 0;
          });
        });
      } finally {
        this.dialog = false;
        this.bookingDialog = true;
      }
    },
    opendialog(date) {
      // открыть попап где будут тарифы опреденного дня
      if (!this.allowedDates(date)) return;
      this.stockModel.id = null;
      let arr = this.stocksList;
      let id = "";
      arr.map(el => {
        if (moment(el.day * 1000).format("YYYY-MM-DD") == date) {
          id = el.stock_id;
          this.stockModel.empty = el.empty;
        }
      });
      this.stockModel.date = date;
      this.stockModel.id = id;
      this.getTariffListV2();
    },
    async selectCategory(id) {
      // изменить категорию в календаре
      this.overlay = true;
      this.stockModel.category = id;
      let params = {
        start: new Date(this.$refs.calendar.lastStart.date).getTime() / 1000,
        end: new Date(this.$refs.calendar.lastEnd.date).getTime() / 1000
      };
      let res = await CalendarApiUrls.getLeisureCalendar(id, params);
      this.stocksList = res.dates;
      this.overlay = false;
    },
    async getCategories() {
      // загрузить категории компании
      this.overlay = true;
      let params = {
        is_active: true,
        "page[number]": 1,
        "page[size]": 10000 // нужны все категории
      };
      let resp = await LeisureCategoriesApiUrls.getCategories(params);
      this.catregoryList = resp;
      if (this.catregoryList.length) {
        if (this.stockModel.category) {
          this.selectCategory(this.stockModel.category);
        } else {
          this.selectCategory(this.catregoryList[0].id);
        }
      } else {
        toast.error("Создайте категорию!");
        this.overlay = false;
      }
    },
    prev() {
      this.$refs.calendar.prev();
      this.getCategories();
    },
    next() {
      this.$refs.calendar.next();
      this.getCategories();
    },
    setToday() {
      this.focus = "";
      this.getCategories();
    },
    formatDate(date) {
      return moment(date).format("DD");
    },
    stocksPerDay(date) {
      if (this.stocksList) {
        let arr = this.stocksList;
        let total = "";
        let empty = "";
        arr.map(el => {
          if (moment(el.day * 1000).format("YYYY-MM-DD") == date) {
            total = el.total;
            empty = el.empty;
          }
        });
        return `${empty} / ${total}`;
      } else {
        return;
      }
    }
  }
};
</script>

<style>
.todaysDate {
  background: #ff9800;
}
.single_day {
  cursor: pointer;
  text-align: center;
  padding-top: 20px;
  height: 100%;
  width: 100%;
  transition: 0.22s;
}
.disabledDay {
  background: #d3d3d3;
}
.single_day:hover {
  background-color: rgba(255, 152, 0, 0.4);
}
.dialog_buttons {
  width: 90%;
}
@media screen and (max-width: 600px) {
  .dialog_buttons {
    width: 98%;
  }
}
</style>
