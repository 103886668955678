<template>
  <div>
    <p class="ma-0">
      <span style="color: #b2b2b2">Создатель брони: </span>
      <span v-text="getSteady().created_by"></span>
    </p>
    <v-row>
      <!-- Источник брони -->
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="getSteady().source"
          :items="booking_type"
          item-text="title"
          item-value="id"
          label="Источник брони*:"
          @change="handleChange"
        />
      </v-col>
      <!-- Период -->
      <v-col cols="12" sm="6" md="4">
        <v-menu
          v-model="menu"
          ref="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeTextFormatted"
              label="Период"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="calendarDateRange"
            :pickerDate.sync="pickerDate"
            range
            no-title
            :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Отмена </v-btn>
            <v-btn text color="primary" @click="commitDateRange">
              Применить
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <!-- Время заезда -->
      <v-col class="mt-3" cols="12" sm="6" md="2">
        <v-text-field
          v-model="timeCheckin"
          dense
          label="Время заезда"
          type="time"
          @change="checkTime('in', timeCheckin), handleChange()"
          hide-details
          :ampm="false"
        />
        <span class="textfield_span" v-if="getSteady().is_early"
          >Ранний заезд</span
        >
      </v-col>
      <!-- Время выезда -->
      <v-col class="mt-3" cols="12" sm="6" md="2">
        <v-text-field
          v-model="timeCheckout"
          dense
          label="Время выезда"
          type="time"
          @change="checkTime('out', timeCheckout), handleChange()"
          hide-details
          :ampm="false"
        />
        <span class="textfield_span" v-if="getSteady().is_late"
          >Поздний выезд</span
        >
      </v-col>
      <!-- Выберите категорию у простой брони -->
      <v-col cols="12" sm="6" md="4" v-if="!getSteady().is_group">
        <v-select
          v-model="getSteady().category_id"
          :items="categories"
          item-text="name"
          item-value="id"
          label="Выберите категорию*:"
          @input="getRooms"
        />
      </v-col>
      <!-- Выберите комнату у простой брони -->
      <v-col cols="12" sm="6" md="4" v-if="!getSteady().is_group">
        <v-select
          v-model="getSteady().room"
          item-text="name"
          item-value="id"
          :items="rooms"
          label="Выберите комнату:"
          @input="getSelectedRoom"
        />
        <span
          v-if="getSteady().room_reason"
          v-text="'Причина переселения: ' + getSteady().room_reason"
        ></span>
        <br />
        <span
          v-if="getSteady().old_room"
          v-text="'Прошлая комната: ' + getSteady().old_room"
        ></span>
      </v-col>
      <!-- Одиночное бронирование у простой брони -->
      <v-col
        cols="12"
        md="2"
        v-if="!getSteady().is_time && !getSteady().is_group"
      >
        <v-checkbox
          @change="handleChange"
          v-model="getSteady().is_single"
          label="Одиночное бронирование"
        />
      </v-col>
      <!-- Главные места -->
      <!-- <v-col v-if="!getSteady().is_group" cols="12" md="2">
        <v-row justify="center" class="mr-4">
          <v-btn
            color="warning"
            @click="changeMainPlace(-1, 'main_adult_count')"
            icon
            elevation="2"
            x-small
          >
            <v-icon>mdi-minus-circle</v-icon>
          </v-btn>
          <p class="ml-2">{{ getSteady().main_adult_count }}</p>
          <v-btn
            color="warning"
            @click="changeMainPlace(1, 'main_adult_count')"
            @change="handleChange"
            class="ml-2 mr-3"
            icon
            elevation="2"
            x-small
          >
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
          Взрослый
        </v-row>
        <v-row justify="center" class="mr-6">
          <v-btn
            color="warning"
            @click="changeMainPlace(-1, 'main_child_count')"
            x-small
            icon
            elevation="2"
          >
            <v-icon>mdi-minus-circle</v-icon>
          </v-btn>
          <p class="ml-2">{{ getSteady().main_child_count }}</p>
          <v-btn
            color="warning"
            @change="handleChange"
            @click="changeMainPlace(1, 'main_child_count')"
            class="ml-2 mr-3"
            icon
            elevation="2"
            x-small
          >
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
          Детский
        </v-row>
      </v-col> -->
      <!-- Список категорий в групповой брони -->
      <v-col col="12" v-if="getSteady().is_group">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(item, index) in getSteady().categories"
            :key="item.id + 'a' + index"
          >
            <v-expansion-panel-header>
              <v-row>
                <!-- Выберите категорию в групповой брони -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-select
                    v-model="item.id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Выберите категорию*:"
                    @click="$event.stopPropagation()"
                    @input="getGroupRooms(item)"
                  />
                </v-col>
                <!-- Выберите комнату в групповой брони -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-select
                    v-model="item.rooms"
                    multiple
                    item-text="name"
                    item-value="id"
                    :items="filterHeaderRooms(item)"
                    label="Выберите комнату*:"
                    @click="$event.stopPropagation()"
                    @input="handleChange(), handleChangeGroupRoom(item, index)"
                  />
                </v-col>
              </v-row>
              <template v-slot:actions>
                <!-- Удалить групповую категорию -->
                <v-btn
                  style="padding: 0px !important; min-width: 40px; height: 30px"
                  class="mx-4 pa-0"
                  @click="deleteGroupCategory(index)"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>

                <v-icon> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row
                v-for="(sub_item, sub_index) in item.child_list"
                :key="sub_item.id + item.id + 'a' + index + sub_index"
              >
                <!-- Выберите категорию в групповой брони -->
                <v-col cols="12" sm="4" class="pa-2">
                  <v-select
                    :disabled="
                      sub_item.booking_status != 'reserved' &&
                      sub_item.booking_status != 'booked'
                    "
                    v-model="sub_item.category_id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Выберите категорию*:"
                  />
                </v-col>
                <!-- Выберите комнату в групповой брони -->
                <v-col cols="12" sm="4" class="pa-2">
                  <v-select
                    :disabled="
                      sub_item.booking_status != 'reserved' &&
                      sub_item.booking_status != 'booked'
                    "
                    v-model="sub_item.room"
                    item-text="name"
                    item-value="id"
                    :items="filterContentRooms(sub_item)"
                    label="Выберите комнату:"
                    @input="getSelectedRoom"
                  />
                </v-col>
                <!-- Одиночное бронирование в групповой брони -->
                <v-col cols="12" sm="2" class="pa-2">
                  <v-checkbox
                    @change="handleChange"
                    v-model="sub_item.is_single"
                    label="Одиночное бронирование"
                  />
                </v-col>
                <!-- Главные места в групповой брони -->
                <!-- <v-col cols="12" md="2">
                  <v-row justify="center" class="mr-4">
                    <v-btn
                      color="warning"
                      @click="
                        changeMainAdditionalPlace(
                          -1,
                          'main_adult_count',
                          index,
                          sub_index
                        )
                      "
                      icon
                      elevation="2"
                      x-small
                    >
                      <v-icon>mdi-minus-circle</v-icon>
                    </v-btn>
                    <p class="ml-2" v-text="sub_item.main_adult_count"></p>
                    <v-btn
                      color="warning"
                      @click="
                        changeMainAdditionalPlace(
                          1,
                          'main_adult_count',
                          index,
                          sub_index
                        )
                      "
                      @change="handleChange"
                      class="ml-2 mr-3"
                      icon
                      elevation="2"
                      x-small
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                    Взрослый
                  </v-row>
                  <v-row justify="center" class="mr-6">
                    <v-btn
                      color="warning"
                      @click="
                        changeMainAdditionalPlace(
                          -1,
                          'main_child_count',
                          index,
                          sub_index
                        )
                      "
                      x-small
                      icon
                      elevation="2"
                    >
                      <v-icon>mdi-minus-circle</v-icon>
                    </v-btn>
                    <p class="ml-2" v-text="sub_item.main_child_count"></p>
                    <v-btn
                      color="warning"
                      @change="handleChange"
                      @click="
                        changeMainAdditionalPlace(
                          1,
                          'main_child_count',
                          index,
                          sub_index
                        )
                      "
                      class="ml-2 mr-3"
                      icon
                      elevation="2"
                      x-small
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                    Детский
                  </v-row>
                </v-col> -->
                <!-- Доп места в групповой брони -->
                <v-col cols="12" md="3" class="pa-2">
                  <v-checkbox
                    @change="
                      handleChange(),
                        handleChangeGroupAdditional(index, sub_index)
                    "
                    v-model="sub_item.has_additional"
                    label="Доп. место"
                  />
                </v-col>
                <v-col cols="12" md="9" v-if="!sub_item.has_additional">
                </v-col>
                <!-- Доп места в групповой брони -->
                <v-col cols="12" md="9" v-if="sub_item.has_additional">
                  <v-row>
                    <v-col class="d-flex justify-center" cols="6" sm="4">
                      <v-btn
                        color="warning"
                        @click="
                          changeGroupAdditional(
                            -1,
                            'adult_count',
                            index,
                            sub_index
                          )
                        "
                        icon
                        elevation="2"
                        x-small
                      >
                        <v-icon>mdi-minus-circle</v-icon>
                      </v-btn>
                      <p class="ml-2" v-text="sub_item.adult_count"></p>
                      <v-btn
                        color="warning"
                        @click="
                          changeGroupAdditional(
                            1,
                            'adult_count',
                            index,
                            sub_index
                          )
                        "
                        @change="handleChange"
                        class="ml-2 mr-3"
                        icon
                        elevation="2"
                        x-small
                      >
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                      Взрослый
                    </v-col>
                    <v-col class="d-flex justify-center" cols="6" sm="4">
                      <v-btn
                        color="warning"
                        @click="
                          changeGroupAdditional(
                            -1,
                            'child_count',
                            index,
                            sub_index
                          )
                        "
                        x-small
                        icon
                        elevation="2"
                      >
                        <v-icon>mdi-minus-circle</v-icon>
                      </v-btn>
                      <p class="ml-2" v-text="sub_item.child_count"></p>
                      <v-btn
                        color="warning"
                        @change="handleChange"
                        @click="
                          changeGroupAdditional(
                            1,
                            'child_count',
                            index,
                            sub_index
                          )
                        "
                        class="ml-2 mr-3"
                        icon
                        elevation="2"
                        x-small
                      >
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                      Детский
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Дети без места в групповой брони -->
                <!-- <v-col cols="12" class="py-0">
                  <v-row>
                    <v-col class="py-0" cols="12" sm="3">
                      <span>Дети без места</span>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="3">
                      <v-row justify="center">
                        <v-btn
                          color="warning"
                          @click="changeGroupWithoutPlace(-1, index, sub_index)"
                          x-small
                          icon
                          elevation="2"
                        >
                          <v-icon>mdi-minus-circle</v-icon>
                        </v-btn>
                        <p class="ml-2">{{ sub_item.child_without_place }}</p>
                        <v-btn
                          color="warning"
                          @change="handleChange"
                          @click="changeGroupWithoutPlace(1, index, sub_index)"
                          class="ml-2 mr-3"
                          icon
                          elevation="2"
                          x-small
                        >
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col> -->
                <!-- Период в групповой брони -->
                <v-col
                  cols="12"
                  sm="4"
                  v-if="sub_item.date && sub_item.date.length > 0"
                >
                  <v-menu
                    v-model="sub_item.menu"
                    ref="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="convertDateToInput(sub_item)"
                        label="Период"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="arr_dates[index + sub_index]"
                      range
                      no-title
                      :first-day-of-week="1"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="sub_item.menu = false"
                      >
                        Отмена
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="submitGroupDate(sub_item, index, sub_index)"
                      >
                        Применить
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- Время заезда в групповой брони -->
                <v-col class="mt-3" cols="12" sm="2">
                  <v-text-field
                    v-model="sub_item.hour_check_in"
                    dense
                    label="Время заезда"
                    type="time"
                    :ampm="false"
                    @change="
                      checkGroupTime(
                        'in',
                        sub_item.hour_check_in,
                        index,
                        sub_index
                      ),
                        handleChange()
                    "
                    hide-details
                  />
                  <span class="textfield_span" v-if="sub_item.is_early"
                    >Ранний заезд</span
                  >
                </v-col>
                <!-- Время выезда в групповой брони -->
                <v-col class="mt-3" cols="12" sm="2">
                  <v-text-field
                    v-model="sub_item.hour_check_out"
                    dense
                    label="Время выезда"
                    type="time"
                    :ampm="false"
                    @change="
                      checkGroupTime(
                        'out',
                        sub_item.hour_check_out,
                        index,
                        sub_index
                      ),
                        handleChange()
                    "
                    hide-details
                  />
                  <span class="textfield_span" v-if="sub_item.is_late"
                    >Поздний выезд</span
                  >
                </v-col>
                <!-- Кнопка: booking_status -->
                <v-col cols="12" sm="4" class="group_buttons">
                  <v-btn
                    @click="stepNext(index, sub_index)"
                    v-if="sub_item.id && sub_item.booking_status != 'check_out'"
                    :disabled="disabledBtn"
                    >{{ sub_item.booking_status | convertBookingStatus }}</v-btn
                  >
                  <v-btn
                    v-if="sub_item.id"
                    :disabled="disabledBtn"
                    @click="
                      $emit('openDelete', {
                        id: sub_item.id,
                        index: index,
                        sub_index: sub_index,
                      })
                    "
                    >Отменить бронь</v-btn
                  >
                  <v-btn
                    color="warning"
                    :disabled="disabledBtn"
                    @click="saveGroupBooking(index, sub_index)"
                    >Сохранить</v-btn
                  >
                </v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- Кнопка Добавить категорию в групповой брони -->
      <v-col cols="12" class="py-0" v-if="getSteady().is_group">
        <span style="color: #fb8c00; cursor: pointer" @click="addGroupCategory"
          >+ Добавить категорию</span
        >
      </v-col>
      <!-- Доп. место в простой брони -->
      <v-col cols="12" class="py-0" v-if="!getSteady().is_group">
        <v-row>
          <v-col class="py-0" cols="12" sm="4" md="3">
            <v-checkbox
              @change="handleChangeAdditional"
              v-model="getSteady().has_additional"
              label="Доп. место"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            sm="4"
            md="4"
            v-if="getSteady().has_additional && !getSteady().is_group"
          >
            <v-row justify="center" class="mr-4">
              <v-btn
                color="warning"
                @click="changeAdditionalPlace(-1, 'adult_count')"
                icon
                elevation="2"
                x-small
              >
                <v-icon>mdi-minus-circle</v-icon>
              </v-btn>
              <p class="ml-2">{{ getSteady().adult_count }}</p>
              <v-btn
                color="warning"
                @click="changeAdditionalPlace(1, 'adult_count')"
                @change="handleChange"
                class="ml-2 mr-3"
                icon
                elevation="2"
                x-small
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
              Взрослый
            </v-row>
            <v-row justify="center" class="mr-6">
              <v-btn
                color="warning"
                @click="changeAdditionalPlace(-1, 'child_count')"
                x-small
                icon
                elevation="2"
              >
                <v-icon>mdi-minus-circle</v-icon>
              </v-btn>
              <p class="ml-2">{{ getSteady().child_count }}</p>
              <v-btn
                color="warning"
                @change="handleChange"
                @click="changeAdditionalPlace(1, 'child_count')"
                class="ml-2 mr-3"
                icon
                elevation="2"
                x-small
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
              Детский
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!-- Дети без места в простой брони -->
      <!-- <v-col cols="12" class="py-0" v-if="!getSteady().is_group">
        <v-row>
          <v-col class="py-0" cols="12" sm="3">
            <span>Дети без места</span>
          </v-col>
          <v-col class="py-0" cols="12" sm="3">
            <v-row justify="center">
              <v-btn
                color="warning"
                @click="changeAdditionalPlace(-1, 'child_without_place')"
                x-small
                icon
                elevation="2"
              >
                <v-icon>mdi-minus-circle</v-icon>
              </v-btn>
              <p class="ml-2">{{ getSteady().child_without_place }}</p>
              <v-btn
                color="warning"
                @change="handleChange"
                @click="changeAdditionalPlace(1, 'child_without_place')"
                class="ml-2 mr-3"
                icon
                elevation="2"
                x-small
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col> -->
      <!-- Тип операции -->
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="getSteady().calculation_type"
          :items="priceType"
          label="Тип операции*:"
          @change="handleChange"
        />
      </v-col>
      <!-- Своя цена -->
      <v-col
        cols="12"
        sm="4"
        md="4"
        v-if="
          getSteady().calculation_type === 'fixed' ||
          getSteady().calculation_type === 'full'
        "
      >
        <v-text-field
          @change="handleChange"
          v-model.number="getSteady().custom_price"
          label="Своя цена:"
        />
      </v-col>
      <v-spacer></v-spacer>
      <br />
    </v-row>
    <!-- Удалить по истечению срока: has_expire -->
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-checkbox
          v-model="getSteady().has_expire"
          @change="handleChange"
          label="Удалить по истечению срока?"
        />
      </v-col>
      <v-col cols="12" sm="6" md="12" v-if="getSteady().has_expire">
        <!-- Не убирать! нужно на будущее! Пока неактивный -->
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="getSteady().expire_on_date"
                  label="Держать резерв до:"
                  readonly
                  v-bind="attrs"
                  prepend-icon="mdi-calendar-blank-multiple"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="selectedReserveDate"
                no-title
                scrollable
                :first-day-of-week="1"
                @change="handleChange"
              >
                <v-spacer />
                <v-btn text color="primary" @click="menu3 = false">
                  Отмена
                </v-btn>
                <v-btn text color="primary" @click="setDateReserve">
                  Применить
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="getSteady().expire_on_time"
              @change="handleChange"
              dense
              label="Время:"
              type="time"
              :ampm="false"
              style="padding-top: 15px; margin-top: 4px"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import toast from "@/plugins/toast";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";
import { SalesApiUrls } from "@/services/accommodationRequests/sales.api.js";
export default {
  name: "Step1",
  data() {
    return {
      pickerDate: null,
      disabledBtn: false,
      dateRange: [], // datepicker value в брони
      calendarDateRange: [], // для календаря
      selectedReserveDate: "", // держать резерв до - datepicker value
      menu3: false, // держать резерв до - dialog value
      selectedCategory: null, // выбранная категория в брони
      menu: false,
      arr_dates: [], // datepickers value в групповой брони
      booking_type: [
        { title: "Прямая продажа", id: null },
        // { title: "Виджет", id: -1 }
        // { text: "go2trip", value: "go2trip" },
        // { text: "qaztrip", value: "qaztrip" }
      ],
      priceType: [
        { text: "По тарифу", value: "tariff" },
        { text: "Фиксированная цена", value: "fixed" },
        { text: "Итоговая цена", value: "full" },
      ],
      rooms: [],
    };
  },
  mounted() {
    // this.calendarDateRange = this.dateRange.map((d) => d.split(" ")[0]);
  },
  computed: {
    ...mapGetters(["getSteady"]),
    categories() {
      return this.$store.getters.getPossibleCategories();
    },
    dateRangeText() {
      let check_in = moment(Number(this.getSteady().check_in) * 1000).format(
        "YYYY-MM-DD HH:mm"
      );
      let check_out = moment(Number(this.getSteady().check_out) * 1000).format(
        "YYYY-MM-DD HH:mm"
      );
      this.dateRange = [check_in, check_out];
      return check_in.split(" ")[0] + " ~ " + check_out.split(" ")[0];
    },
    dateRangeTextFormatted() {
      var date = this.dateRangeText;
      if (date.includes(" ~ ")) {
        var dates = date.split(" ~ ");
        var check_in = moment(dates[0]).format("DD.MM.YYYY");
        var check_out = moment(dates[1]).format("DD.MM.YYYY");
        return check_in + " ~ " + check_out;
      }
    },
    timeCheckin: {
      get() {
        let check_in = moment(Number(this.getSteady().check_in) * 1000).format(
          "YYYY-MM-DD HH:mm"
        );
        return check_in.split(" ")[1];
      },
      set(val) {
        if (!val) return;
        let check_in = moment(Number(this.getSteady().check_in) * 1000).format(
          "YYYY-MM-DD HH:mm"
        );
        let year = check_in.split(" ")[0].split("-")[0],
          month = Number(check_in.split(" ")[0].split("-")[1]) - 1,
          day = check_in.split(" ")[0].split("-")[2],
          hours = val.split(":")[0],
          minutes = val.split(":")[1];
        check_in = new Date(year, month, day, hours, minutes);
        this.getSteady().check_in = check_in.getTime() / 1000;
      },
    },
    timeCheckout: {
      get() {
        let check_out = moment(
          Number(this.getSteady().check_out) * 1000
        ).format("YYYY-MM-DD HH:mm");
        return check_out.split(" ")[1];
      },
      set(val) {
        if (!val) return;
        let check_out = moment(
          Number(this.getSteady().check_out) * 1000
        ).format("YYYY-MM-DD HH:mm");
        let year = check_out.split(" ")[0].split("-")[0],
          month = Number(check_out.split(" ")[0].split("-")[1]) - 1,
          day = check_out.split(" ")[0].split("-")[2],
          hours = val.split(":")[0],
          minutes = val.split(":")[1];
        check_out = new Date(year, month, day, hours, minutes);
        this.getSteady().check_out = check_out.getTime() / 1000;
      },
    },
  },
  watch: {
    menu(isShow) {
  if (isShow) {
    this.$nextTick(() => {
      const today = this.dateRange.map((d) => d.split(" ")[0]);
      this.pickerDate = today[0];
      // console.log(`pickerDate updated: ${today}, isShow:${isShow}`);
    });
  }
},
    dateRange: {
      handler(newVal) {
        this.updateCalendarDateRange();
      },
      deep: true, // если dateRange является вложенным массивом
    },
    calendarDateRange(value) {
      for (let i = 0; i < value.length; i++) {
        if (i === 0) {
          this.dateRange[0] = value[0] + " " + this.timeCheckin;
        } else {
          this.dateRange[1] = value[1] + " " + this.timeCheckout;
        }
      }
    },
    "$store.state.open_click"() {
      if (this.getSteady().is_group) {
        this.arr_dates = [];
        this.getSteady().categories.forEach((e) => {
          e.child_list.forEach((j) => {
            this.arr_dates.push(j.date);
          });
        });
      }
    },
  },
  filters: {
    convertBookingStatus(item) {
      const statuses = {
        reserved: "Бронирование",
        booked: "Заселить",
        check_in: "Выселить",
      };
      return statuses[item];
    },
  },
  async created() {
    this.updateCalendarDateRange();

    let resp = await SalesApiUrls.getSales({
      "page[number]": 1,
      "page[size]": 100000,
    });
    this.booking_type = [{ title: "Прямая продажа", id: null }];
    this.booking_type.push(...resp.results);
    if (this.categories.length) {
      this.selectedCategory = this.categories.filter(
        (val) => val.id === this.getSteady().category_id
      )[0];
      let response = await CategoriesApiUrls.getSelectedRooms(
        this.getSteady().category_id
      );
      this.rooms = response;
    }
  },
  methods: {
    test() {
      console.log("datarange: " + this.dateRange);
      console.log("calendarDateRange: " + this.calendarDateRange)
    },
    clearDateRange() {
      this.calendarDateRange = [];
      this.menu = false;
    },
    updateCalendarDateRange() {
      this.calendarDateRange = this.dateRange.map((d) => d.split(" ")[0]);
    },
    // изменить статус одной брони в групповой брони
    async stepNext(index, sub_index) {
      let id = this.getSteady().categories[index].child_list[sub_index].id;
      this.disabledBtn = true;
      try {
        let resp = await BookingApiUrls.changeRealBooking(
          id,
          this.getSteady().categories[index].child_list[sub_index]
        );
        toast.success("Изменено!");
        resp = resp.data;
        let check_in = moment(Number(resp.check_in) * 1000).format(
          "YYYY-MM-DD"
        );
        let check_out = moment(Number(resp.check_out) * 1000).format(
          "YYYY-MM-DD"
        );
        let hour_check_in = moment(Number(resp.check_in) * 1000).format(
          "HH:mm"
        );
        let hour_check_out = moment(Number(resp.check_out) * 1000).format(
          "HH:mm"
        );
        resp.is_early = false;
        resp.is_late = false;
        resp.hour_check_in = hour_check_in;
        resp.hour_check_out = hour_check_out;
        resp.date = [check_in, check_out];
        resp.menu = false;
        this.getSteady().categories[index].child_list.splice(
          sub_index,
          1,
          resp
        );
      } finally {
        this.disabledBtn = false;
      }
    },
    // сохранить одну бронь в групповой брони
    async saveGroupBooking(index, sub_index) {
      let id = this.getSteady().categories[index].child_list[sub_index].id;
      let from =
        moment(
          new Date(
            +this.getSteady().categories[index].child_list[sub_index].check_in *
              1000
          )
        ).format("YYYY-MM-DD") +
        " " +
        this.getSteady().categories[index].child_list[sub_index].hour_check_in;
      let to =
        moment(
          new Date(
            +this.getSteady().categories[index].child_list[sub_index]
              .check_out * 1000
          )
        ).format("YYYY-MM-DD") +
        " " +
        this.getSteady().categories[index].child_list[sub_index].hour_check_out;
      let resp;
      if (id) {
        this.disabledBtn = true;
        try {
          let body = {};
          body = {
            ...this.getSteady().categories[index].child_list[sub_index],
            check_in: from,
            check_out: to,
          };
          resp = await BookingApiUrls.setBooking(id, body);
          toast.success("Изменено!");
        } finally {
          this.disabledBtn = false;
        }
      } else {
        this.disabledBtn = true;
        let body = {
          check_in: from,
          check_out: to,
          room: this.getSteady().categories[index].child_list[sub_index].room,
          is_time: false,
          is_group: false,
          group_parent: this.getSteady().group_parent,
          source: this.getSteady().source,
        };
        console.log(body, this.getSteady());
        try {
          resp = await BookingApiUrls.reserveBooking(body);
          toast.success("Изменено!");
        } finally {
          this.disabledBtn = false;
        }
      }
      resp = resp.data;
      let check_in = moment(Number(resp.check_in) * 1000).format("YYYY-MM-DD");
      let check_out = moment(Number(resp.check_out) * 1000).format(
        "YYYY-MM-DD"
      );
      let hour_check_in = moment(Number(resp.check_in) * 1000).format("HH:mm");
      let hour_check_out = moment(Number(resp.check_out) * 1000).format(
        "HH:mm"
      );
      resp.is_early = false;
      resp.is_late = false;
      resp.hour_check_in = hour_check_in;
      resp.hour_check_out = hour_check_out;
      resp.date = [check_in, check_out];
      resp.menu = false;
      this.getSteady().categories[index].child_list.splice(sub_index, 1, resp);
    },
    // изменить кол-во детей без места в груповой брони
    changeGroupWithoutPlace(number, index, sub_index) {
      if (
        this.getSteady().categories[index].child_list[sub_index]
          .child_without_place < 1 &&
        number < 0
      ) {
        toast.error("Не может быть отрицательное число!");
        return;
      } else {
        let numb =
          this.getSteady().categories[index].child_list[sub_index]
            .child_without_place + +number;
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          "child_without_place",
          numb
        );
      }
    },
    // изменить кол-во доп мест в груповой брони
    changeGroupAdditional(number, type, index, sub_index) {
      let category = this.categories.find(
        (e) => e.id == this.getSteady().categories[index].id
      );
      if (
        number > 0 &&
        this.getSteady().categories[index].child_list[sub_index].adult_count +
          this.getSteady().categories[index].child_list[sub_index]
            .child_count ==
          category.additional_place
      ) {
        toast.error("Не достаточно мест!");
        return;
      }
      if (
        type == "child_count" &&
        number > 0 &&
        category.adult_count > this.getSteady().adult_count &&
        this.getSteady().categories[index].child_list[sub_index].adult_count +
          this.getSteady().categories[index].child_list[sub_index].child_count <
          category.additional_place
      ) {
        let total =
          this.getSteady().categories[index].child_list[sub_index][type] +
          number;
        delete this.getSteady().categories[index].child_list[sub_index][type];
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          type,
          total
        );
      } else if (
        category[type] >=
        this.getSteady().categories[index].child_list[sub_index][type] + +number
      ) {
        if (
          this.getSteady().categories[index].child_list[sub_index][type] +
            +number >=
          0
        ) {
          let total =
            this.getSteady().categories[index].child_list[sub_index][type] +
            number;
          delete this.getSteady().categories[index].child_list[sub_index][type];
          this.$set(
            this.getSteady().categories[index].child_list[sub_index],
            type,
            total
          );
        }
      } else if (
        number < 0 &&
        this.getSteady().categories[index].child_list[sub_index].adult_count +
          this.getSteady().categories[index].child_list[sub_index].child_count >
          category.additional_place
      ) {
        let total =
          this.getSteady().categories[index].child_list[sub_index][type] +
          number;
        delete this.getSteady().categories[index].child_list[sub_index][type];
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          type,
          total
        );
      } else {
        toast.error("Не достаточно мест!");
      }
    },
    // изменить кол-во главных мест в груповой брони
    changeMainAdditionalPlace(number, type, index, sub_index) {
      let category = this.categories.find(
        (e) => e.id == this.getSteady().categories[index].id
      );
      let numb =
        this.getSteady().categories[index].child_list[sub_index]
          .main_adult_count +
        this.getSteady().categories[index].child_list[sub_index]
          .main_child_count +
        +number;
      if (
        numb <= category.place_number &&
        this.getSteady().categories[index].child_list[sub_index][type] +
          +number >=
          0
      ) {
        let total =
          this.getSteady().categories[index].child_list[sub_index][type] +
          number;
        delete this.getSteady().categories[index].child_list[sub_index][type];
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          type,
          total
        );
      } else if (number < 0 && numb > category.place_number) {
        let total =
          this.getSteady().categories[index].child_list[sub_index][type] +
          number;
        delete this.getSteady().categories[index].child_list[sub_index][type];
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          type,
          total
        );
      } else {
        toast.error("Не достаточно мест!");
        return;
      }
    },
    // если checkbox===true, то сделать поля по 0 доп места в простой брони
    handleChangeAdditional() {
      this.handleChange();
      if (!this.getSteady().has_additional) {
        this.$set(this.getSteady(), "adult_count", 0);
        this.$set(this.getSteady(), "child_count", 0);
      }
    },
    // сделать поля по 0 доп места в групповой брони
    handleChangeGroupAdditional(index, sub_index) {
      let value =
        this.getSteady().categories[index].child_list[sub_index].has_additional;
      delete this.getSteady().categories[index].child_list[sub_index]
        .has_additional;
      this.$set(
        this.getSteady().categories[index].child_list[sub_index],
        "has_additional",
        value
      );
      if (!value) {
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          "adult_count",
          0
        );
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          "child_count",
          0
        );
      }
    },
    // хендлить изменения времени в групповой брони
    checkGroupTime(type, time, index, sub_index) {
      if (
        type == "in" &&
        `${time}:00` >= localStorage.getItem("check_in_time") &&
        this.getSteady().categories[index].child_list[sub_index].is_early
      ) {
        delete this.getSteady().categories[index].child_list[sub_index]
          .is_early;
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          "is_early",
          false
        );
      }
      if (
        type == "in" &&
        `${time}:00` < localStorage.getItem("check_in_time")
      ) {
        delete this.getSteady().categories[index].child_list[sub_index]
          .is_early;
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          "is_early",
          true
        );
      }
      if (
        type == "out" &&
        `${time}:00` >= localStorage.getItem("check_out_time") &&
        this.getSteady().categories[index].child_list[sub_index].is_late
      ) {
        delete this.getSteady().categories[index].child_list[sub_index].is_late;
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          "is_late",
          false
        );
      }
      if (
        type == "out" &&
        `${time}:00` > localStorage.getItem("check_out_time")
      ) {
        delete this.getSteady().categories[index].child_list[sub_index].is_late;
        this.$set(
          this.getSteady().categories[index].child_list[sub_index],
          "is_late",
          true
        );
      }
    },
    // хендлить изменения комнат в групповой брони из header списка
    handleChangeGroupRoom(item, index) {
      // добавить в child_list если в rooms есть новые значения
      console.log("handle change group");
      console.log("item: ", item);
      let obj = {};
      this.getSteady().categories[index].rooms.forEach((i) => {
        obj[i] = 0;
        this.getSteady().categories[index].child_list.forEach((j) => {
          if (i === j.room) {
            obj[i]++;
          }
        });
      });
      for (const [key, value] of Object.entries(obj)) {
        if (value == 0) {
          let obj_1 =
            this.getSteady().categories[index].child_list[
              this.getSteady().categories[index].child_list.length - 1
            ];
          this.getSteady().categories[index].child_list.push({
            ...obj_1,
            room: +key,
            id: null, // проблема тут
          });
        }
      }

      // убрать из child_list
      this.getSteady().categories[index].child_list.forEach((i, ind) => {
        if (!this.getSteady().categories[index].rooms.includes(i.room)) {
          this.getSteady().categories[index].child_list.splice(ind, 1);
        }
      });
      this.$store.commit("SET_OPEN_CLICK", 1);
    },
    convertDateToInput(item) {
      let check_in = moment(item.check_in * 1000).format("DD.MM.YYYY");
      let check_out = moment(item.check_out * 1000).format("DD.MM.YYYY");
      return check_in + " ~ " + check_out;
    },
    // добавить date в бронь из групповой брони
    submitGroupDate(item, index, sub_index) {
      this.getSteady().categories[index].child_list[sub_index].menu = false;

      let start = moment(this.arr_dates[index + sub_index][0]).format(
        "YYYY-MM-DD"
      );
      let end = moment(this.arr_dates[index + sub_index][1]).format(
        "YYYY-MM-DD"
      );
      let timeCheckin =
        this.getSteady().categories[index].child_list[sub_index].hour_check_in;
      let timeCheckout =
        this.getSteady().categories[index].child_list[sub_index].hour_check_out;
      if (start > end) {
        let temp = start;
        start = end;
        end = temp;
      }
      this.dateRange = [
        moment(start).format(`YYYY-MM-DD ${timeCheckin}`), // ${this.timeCheckin}
        moment(end).format(`YYYY-MM-DD ${timeCheckout}`),
      ];
      this.getSteady().categories[index].child_list[sub_index].check_in =
        moment(this.dateRange[0]).valueOf() / 1000;
      this.getSteady().categories[index].child_list[sub_index].check_out =
        moment(this.dateRange[1]).valueOf() / 1000;
    },
    deleteGroupCategory(index) {
      this.getSteady().categories.splice(index, 1);
    },
    addGroupCategory() {
      let arr = {
        id: this.getSteady().categories[this.getSteady().categories.length - 1]
          .id,
        rooms: [],
        child_list: [
          {
            ...this.getSteady().categories[
              this.getSteady().categories.length - 1
            ].child_list[0],
            id: null,
            room: null,
          },
        ],
      };
      this.getSteady().categories.push(arr);
      this.$store.commit("SET_OPEN_CLICK", 1);
    },
    filterHeaderRooms(item) {
      let arr = [];
      this.categories.forEach((element) => {
        if (element.id == item.id) arr = element.rooms;
      });
      return arr;
    },
    filterContentRooms(sub_item) {
      let arr = [];
      this.categories.forEach((element) => {
        if (element.id == sub_item.category_id) arr = element.rooms;
      });
      return arr;
    },
    handleChange() {
      console.log("handle change");
      this.$store.commit("SET_UPDATED_STEADY", 1);
    },
    setDateReserve() {
      console.log(this.selectedReserveDate);
      let year = this.selectedReserveDate.split("-")[0],
        month = Number(this.selectedReserveDate.split("-")[1]) - 1,
        day = this.selectedReserveDate.split("-")[2];
      this.getSteady().expire_on_date = moment(
        new Date(year, month, day).getTime()
      ).format("DD.MM.YYYY");
      console.log(this.getSteady().expire_on_date);
      this.menu3 = false;
    },
    // хендлить изменения времени в простой брони
    checkTime(type, time) {
      if (
        type == "in" &&
        `${time}:00` >= localStorage.getItem("check_in_time") &&
        this.getSteady().is_early == true
      ) {
        this.getSteady().is_early = false;
      }
      if (
        type == "in" &&
        `${time}:00` < localStorage.getItem("check_in_time")
      ) {
        this.getSteady().is_early = true;
      }
      if (
        type == "out" &&
        `${time}:00` >= localStorage.getItem("check_out_time") &&
        this.getSteady().is_late == true
      ) {
        this.getSteady().is_late = false;
      }
      if (
        type == "out" &&
        `${time}:00` > localStorage.getItem("check_out_time")
      ) {
        this.getSteady().is_late = true;
      }
    },
    // добавить date в простую бронь
    commitDateRange() {
      this.handleChange();
      let start = moment(this.dateRange[0]).format("YYYY-MM-DD");
      let end = moment(this.dateRange[1]).format("YYYY-MM-DD");
      if (start > end) {
        let temp = start;
        start = end;
        end = temp;
      }
      this.dateRange = [
        moment(start).format(`YYYY-MM-DD ${this.timeCheckin}`),
        moment(end).format(`YYYY-MM-DD ${this.timeCheckout}`),
      ];
      this.getSteady().check_in = moment(this.dateRange[0]).valueOf() / 1000;
      this.getSteady().check_out = moment(this.dateRange[1]).valueOf() / 1000;
      this.menu = false;
    },
    // хендлить изменения доп мест и детей без места в простой брони
    changeAdditionalPlace(numb, type) {
      if (type == "child_without_place") {
        if (+numb < 0 && this.getSteady()[type] < 1) {
          toast.error("Меньше нельзя!");
          return;
        } else {
          this.getSteady()[type] += +numb;
          return;
        }
      }
      if (
        numb > 0 &&
        this.getSteady().adult_count + this.getSteady().child_count ==
          this.selectedCategory.additional_place
      ) {
        toast.error("Не достаточно мест!");
        return;
      } else if (
        this.getSteady().adult_count + this.getSteady().child_count >
          this.selectedCategory.additional_place &&
        numb < 0
      ) {
        this.getSteady()[type] += +numb;
        return;
      }
      if (
        type == "child_count" &&
        numb > 0 &&
        this.selectedCategory.adult_count > this.getSteady().adult_count &&
        this.getSteady().adult_count + this.getSteady().child_count <
          this.selectedCategory.additional_place
      ) {
        this.getSteady()[type] += +numb;
      } else if (
        this.selectedCategory[type] >=
        this.getSteady()[type] + +numb
      ) {
        if (this.getSteady()[type] + +numb >= 0) {
          this.getSteady()[type] += +numb;
        }
      } else {
        toast.error("Не достаточно мест!");
      }
    },
    // хендлить изменения главных мест в простой брони
    changeMainPlace(numb, type) {
      if (
        numb > 0 &&
        this.getSteady().main_adult_count + this.getSteady().main_child_count ==
          this.selectedCategory.place_number
      ) {
        toast.error("Не достаточно мест!");
        return;
      }
      if (
        type == "main_child_count" &&
        numb > 0 &&
        this.selectedCategory.main_adult_count >
          this.getSteady().main_adult_count &&
        this.getSteady().main_adult_count + this.getSteady().main_child_count <
          this.selectedCategory.place_number
      ) {
        this.getSteady()[type] += +numb;
      } else if (
        this.selectedCategory.place_number >=
        this.getSteady()[type] + +numb
      ) {
        if (this.getSteady()[type] + +numb >= 0) {
          this.getSteady()[type] += +numb;
        }
      } else if (
        this.selectedCategory.place_number <= this.getSteady()[type] + +numb &&
        numb < 0
      ) {
        if (this.getSteady()[type] + +numb >= 0) {
          this.getSteady()[type] += +numb;
        }
      } else {
        toast.error("Не достаточно мест!");
      }
    },
    async getGroupRooms(item) {
      item.rooms = [];
      item.child_list = [
        {
          ...item.child_list[0],
          id: null,
          room: null,
        },
      ];
    },
    async getRooms(item) {
      this.selectedCategory = this.categories.filter((el) => el.id === item)[0];
      let response = await CategoriesApiUrls.getSelectedRooms(
        this.getSteady().category_id
      );
      this.rooms = response;
    },
    getSelectedRoom(item) {
      this.handleChange();
      this.$store.commit("SET_ROOM", item);
      if (this.getSteady().booking_status == "check_in") {
        this.$emit("openRoomReason");
      }
    },
  },
};
</script>

<style scoped>
.textfield_span {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ed9135;
}
::v-deep .v-expansion-panel-header {
  padding: 5px 10px 5px 10px;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  border-radius: 0px;
}
::v-deep .v-expansion-panel::before {
  box-shadow: none;
  border-bottom: 1px solid gray;
  border-top: 1px solid gray;
}
::v-deep .v-expansion-panel--active:not(:first-child) {
  margin-top: 0px;
}
.group_buttons {
  display: grid;
  grid-template-columns: 33% 37% 30%;
  gap: 10px;
}
@media screen and (max-width: 500px) {
  .group_buttons {
    grid-template-columns: auto;
  }
}
</style>
