<template>
  <div>
    <strong class="ml-5">итого</strong>
    <v-divider class="hr-divider" />
    <!-- Тип расчета -->
    <v-row class="ml-3">
      <v-col class="pb-0" cols="6" md="2" sm="4">
        <b>Тип расчета:</b>
      </v-col>
      <v-col class="pb-0" cols="6" md="2" sm="4">
        {{ paymentInfo.calculation_type }}
      </v-col>
    </v-row>
    <!-- Кол-во суток -->
    <v-row v-if="!getSteady().is_time" class="ml-3">
      <v-col class="pb-0" cols="6" md="2" sm="4">
        <b>Суток:</b>
      </v-col>
      <v-col class="pb-0" cols="6" md="2" sm="4">
        {{ paymentInfo.days }}
      </v-col>
    </v-row>
    <!-- Кол-во часов -->
    <v-row v-if="getSteady().is_time" class="ml-3">
      <v-col class="pb-0" cols="6" md="2" sm="4">
        <b>Часов:</b>
      </v-col>
      <v-col class="pb-0" cols="6" md="2" sm="4">
        {{ paymentInfo.days }}
      </v-col>
    </v-row>
    <!-- За проживание -->
    <v-row class="ml-3" v-if="paymentInfo.price > 0">
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>За проживание:</b>
      </v-col>
      <v-col cols="6" md="2" sm="4" class="py-0">
        {{ Number(paymentInfo.price).toLocaleString("ru-RU") }} тг
      </v-col>
    </v-row>
    <!-- Ранний заезд -->
    <v-row
      v-if="!getSteady().is_time && paymentInfo.early_check_in > 0"
      class="ml-3"
    >
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>Ранний заезд:</b>
      </v-col>
      <v-col cols="6" md="2" sm="4" class="py-0">
        {{ Number(paymentInfo.early_check_in).toLocaleString("ru-RU") }} тг
      </v-col>
    </v-row>
    <!-- Поздний выезд -->
    <v-row
      v-if="!getSteady().is_time && paymentInfo.late_check_out > 0"
      class="ml-3"
    >
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>Поздний выезд:</b>
      </v-col>
      <v-col cols="6" md="2" sm="4" class="py-0">
        {{ Number(paymentInfo.late_check_out).toLocaleString("ru-RU") }} тг
      </v-col>
    </v-row>
    <!-- Услуги -->
    <v-row
      class="ml-3"
      v-if="paymentInfo.services && paymentInfo.services.length"
    >
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>Услуги:</b>
      </v-col>
    </v-row>
    <!-- Список услуг -->
    <div v-if="paymentInfo.services && paymentInfo.services.length">
      <v-row
        class="ml-3 py-3"
        v-for="(service, index) in paymentInfo.services"
        :key="index"
      >
        <v-col cols="6" md="2" sm="4" class="pt-0 pb-1 pl-4">
          {{ service.name }}
        </v-col>
        <v-col cols="6" md="2" sm="4" class="pt-0 pb-1">
          {{ Number(service.price).toLocaleString("ru-RU") }} тг
        </v-col>
      </v-row>
    </div>
    <!-- Питание -->

    <v-row
      class="ml-3 pb-3 pt-1 row "
    >
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>Питание:</b>
      </v-col>
    </v-row>
    <!-- Список питания -->
    <div v-if="this.getSteady().food_list && this.getSteady().food_list.length">
      <v-row
        class="ml-3 py-3 row"
        v-for="(food, index) in paymentInfo.food_list"
        :key="index"
      >
        <v-col cols="6" md="2" sm="4" class="pt-0 pb-1  pl-4">
          {{ foodTypes[food.tariff.food_type] }}
        </v-col>
        <v-col cols="6" md="2" sm="4" class="pt-0 pb-1">
          {{ Number(food.total).toLocaleString("ru-RU") }} тг
        </v-col>
      </v-row>
    </div>
    <!-- Доп. места -->
    <v-row
      class="ml-3"
      v-if="paymentInfo.adult_price + paymentInfo.child_price > 0"
    >
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>Доп. места:</b>
      </v-col>
      <v-col cols="6" md="2" sm="4" class="pt-0">
        {{
          Number(
            paymentInfo.adult_price + paymentInfo.child_price
          ).toLocaleString("ru-RU")
        }}
        тг
      </v-col>
    </v-row>
    <!-- Дети без места -->
    <v-row class="ml-3 my-0 py-0" v-if="paymentInfo.child_without_place > 0">
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>Дети без места:</b>
      </v-col>
      <v-col cols="6" md="2" sm="4" class="pt-0">
        {{ Number(paymentInfo.child_without_place).toLocaleString("ru-RU") }}
        тг
      </v-col>
    </v-row>
    <!-- Раннее бронирование -->
    <v-row class="ml-3 mb-0" v-if="paymentInfo.advance_booking > 0">
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>Раннее бронирование:</b>
      </v-col>
      <v-col cols="6" md="2" sm="4" class="pt-0">
        {{ Number(paymentInfo.advance_booking).toLocaleString("ru-RU") }} тг
      </v-col>
    </v-row>
    <v-divider class="hr-divider" />
    <!-- Итого -->
    <v-row class="ml-3 mt-0 mb-0" v-if="paymentInfo.total > 0">
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>Итого:</b>
      </v-col>
      <v-col cols="6" md="2" sm="4" class="pt-0">
        {{ Number(paymentInfo.total).toLocaleString("ru-RU") }} тг
      </v-col>
    </v-row>
    <!-- Категории в групповой брони -->
    <v-col cols="12" md="6" v-if="getSteady().is_group">
      <div
        v-for="(item, index) in getSteady().group_calculation.items"
        :key="index"
      >
        <p class="group_title mt-2" v-text="item.category"></p>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="item_room in item.rooms"
            :key="item_room.room"
          >
            <v-expansion-panel-header>
              <span class="group_title_room" v-text="item_room.room"></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6" md="4">Суток: </v-col>
                <v-col cols="6" md="7" v-text="item_room.days"></v-col>
                <v-col cols="6" md="4" v-if="item_room.price > 0"
                  >За проживание:
                </v-col>
                <v-col cols="6" md="7" v-if="item_room.price > 0"
                  >{{
                    Number(item_room.price).toLocaleString("ru-RU")
                  }}
                  тг</v-col
                >
                <v-col cols="6" md="4" v-if="item_room.early_check_in > 0"
                  >Ранний заезд:
                </v-col>
                <v-col cols="6" md="7" v-if="item_room.early_check_in > 0"
                  >{{
                    Number(item_room.early_check_in).toLocaleString("ru-RU")
                  }}
                  тг</v-col
                >
                <v-col cols="6" md="4" v-if="item_room.late_check_out > 0"
                  >Поздний выезд:
                </v-col>
                <v-col cols="6" md="7" v-if="item_room.late_check_out > 0"
                  >{{
                    Number(item_room.late_check_out).toLocaleString("ru-RU")
                  }}
                  тг</v-col
                >
                <v-col
                  cols="6"
                  md="4"
                  v-if="item_room.adult_price + item_room.child_price > 0"
                  >Доп. места:
                </v-col>
                <v-col
                  cols="6"
                  md="7"
                  v-if="item_room.adult_price + item_room.child_price > 0"
                  >{{
                    Number(
                      item_room.adult_price + item_room.child_price
                    ).toLocaleString("ru-RU")
                  }}
                  тг</v-col
                >
                <v-col cols="6" md="4" v-if="item_room.child_without_place > 0"
                  >Дети без места:
                </v-col>
                <v-col cols="6" md="7" v-if="item_room.child_without_place > 0"
                  >{{
                    Number(item_room.child_without_place).toLocaleString(
                      "ru-RU"
                    )
                  }}
                  тг</v-col
                >
                <v-col cols="6" md="4" v-if="item_room.advance_booking > 0"
                  >Раннее бронирование:
                </v-col>
                <v-col cols="6" md="7" v-if="item_room.advance_booking > 0"
                  >{{
                    Number(item_room.advance_booking).toLocaleString("ru-RU")
                  }}
                  тг</v-col
                >
                <v-col cols="6" md="4" v-if="item_room.services.length > 0"
                  >Услуги:
                </v-col>
                <v-col
                  cols="6"
                  md="7"
                  v-if="item_room.services.length > 0"
                ></v-col>
                <v-col cols="12" class="pa-0">
                  <v-row
                    class="ml-3 py-3 row"
                    v-for="item_service in item_room.services"
                    :key="item_service.id"
                  >
                    <v-col cols="6" md="4" v-text="item_service.name"></v-col>
                    <v-col cols="6" md="7"
                      >{{
                        Number(item_service.price).toLocaleString("ru-RU")
                      }}
                      тг</v-col
                    >
                  </v-row>
                </v-col>
                <!-- Питание -->
                <v-col cols="6" md="4"
                  >Питание:
                </v-col>
                <v-col
                  cols="6"
                  md="7"
                ></v-col>
                <v-col cols="12" class="pa-0">
                  <v-row
                    class="ml-4"
                    v-for="item_service in item_room.food_list"
                    :key="item_service.id"
                  >
                    <v-col cols="6" md="4" v-text="foodTypes[item_service.tariff.food_type]"></v-col>
                    <v-col cols="6" md="7"
                      >{{
                        Number(item_service.total).toLocaleString("ru-RU")
                      }}
                      тг</v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-col>
    <v-divider class="hr-divider" />
    <!-- Удержано -->
    <v-row class="ml-3" v-if="has_return">
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>Удержано</b>
      </v-col>
      <v-col cols="6" md="2" sm="4" class="pt-0">
        {{ Number(paymentInfo.withheld).toLocaleString("ru-RU") }} тг
      </v-col>
    </v-row>
    <!-- К возврату -->
    <v-row class="ml-3" v-if="has_return">
      <v-col cols="6" md="2" sm="4" class="py-0">
        <b>К возврату</b>
      </v-col>
      <v-col cols="6" md="2" sm="4" class="pt-0">
        {{ Number(paymentInfo.return_amount).toLocaleString("ru-RU") }} тг
      </v-col>
    </v-row>
    <v-divider class="hr-divider" />
    <!-- К оплате, Оплачено, Осталось оплатить, Скидки, Промокоды -->
    <v-row class="mt-1">
      <v-col cols="6" md="3" class="py-md-3 py-1">
        <v-text-field
          disabled
          class="float-left"
          outlined
          dense
          :value="paymentInfo.total"
        >
          <span
            slot="prepend-inner"
            style="font-size: 12px; margin-top:6px;display:inline-block;width:max-content"
          >
            К оплате:
          </span>
        </v-text-field>
      </v-col>
      <v-col cols="6" md="3" class="py-md-3 py-1">
        <v-text-field
          disabled
          class="float-left"
          outlined
          dense
          :value="paymentInfo.paid_amount"
        >
          <span slot="prepend-inner" style="font-size: 12px;margin-top:6px;">
            Оплачено:
          </span>
        </v-text-field>
      </v-col>
      <v-col cols="6" md="3" class="py-md-3 py-1">
        <v-text-field
          disabled
          class="float-left"
          outlined
          dense
          :value="paymentInfo.total - paymentInfo.paid_amount"
        >
          <span slot="prepend-inner" style="font-size: 12px">
            Осталось оплатить:
          </span>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-btn-toggle class="px-3" mandatory>
            <v-btn
              style="width:50%;font-size:11px"
              :color="isPromocode == 'discount' ? 'warning' : 'white'"
              @click="isPromocode = 'discount'"
            >
              Добавить скидку
            </v-btn>
            <v-btn
              style="width:50%;font-size:11px;"
              :color="isPromocode == 'promo' ? 'warning' : 'white'"
              @click="isPromocode = 'promo'"
            >
              Добавить промокод
            </v-btn>
          </v-btn-toggle>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-if="isPromocode === 'promo'"
              label="Промокод"
              v-model="promcodeSearch"
              @input="getPromo"
              dense
            />
            <v-select
              v-if="isPromocode === 'discount'"
              class="ml-2"
              :items="discountItems"
              v-model="selectedDiscount"
              :item-text="discountText"
              return-object
              item-value="id"
              label="Скидка"
              @change="handleChangeDiscount"
            />
            <div
              v-if="
                selectedDiscount && selectedDiscount.discount_kind == 'fixed'
              "
              class="d-inline-block"
              style="width:50%;vertical-align:bottom"
            >
              <v-btn
                class="mr-2"
                small
                icon
                outlined
                color="warning"
                @click="changeDiscoutQuantity('-')"
                :disabled="selectedDiscount.value == 0"
                ><v-icon>
                  mdi-minus
                </v-icon></v-btn
              >
              <span>{{ selectedDiscount.value }}</span>
              <v-btn
                class="ml-2"
                small
                icon
                outlined
                color="warning"
                @click="changeDiscoutQuantity('+')"
                ><v-icon>
                  mdi-plus
                </v-icon></v-btn
              >
            </div>
            <v-btn
              class="ml-4 mt-2"
              tile
              small
              dense
              outlined
              color="warning"
              style="width: 110px !important"
              @click="calculateDiscount"
              :disabled="disabledBtn"
            >
              <v-icon left> mdi-plus </v-icon>
              Добавить
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-row
              v-for="(selected_disc, ind) in getSteady().discount_list"
              :key="'disc' + selected_disc.id + ind"
            >
              <v-col
                v-if="
                  selected_disc.discount_upd &&
                    selected_disc.discount_upd.discount_kind == 'fixed'
                "
                cols="3"
                md="1"
                class="d-flex justify-center"
              >
                <v-btn
                  class="mt-2"
                  small
                  icon
                  outlined
                  color="warning"
                  @click="changeRealDiscoutQuantity(ind, '-')"
                  :disabled="selected_disc.quantity == 0"
                  ><v-icon>
                    mdi-minus
                  </v-icon></v-btn
                >
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  disabled
                  class="float-left"
                  outlined
                  dense
                  :value="
                    (selected_disc.discount_upd &&
                    selected_disc.discount_upd.name
                      ? selected_disc.discount_upd.name
                      : selected_disc.name) +
                      ' (' +
                      (selected_disc.discount_upd &&
                      selected_disc.discount_upd.quantity
                        ? selected_disc.discount_upd.quantity
                        : selected_disc.quantity) +
                      ')'
                  "
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="3"
                md="1"
                v-if="
                  selected_disc.discount_upd &&
                    selected_disc.discount_upd.discount_kind == 'fixed'
                "
              >
                <v-btn
                  class="mt-2"
                  small
                  icon
                  outlined
                  color="warning"
                  @click="changeRealDiscoutQuantity(ind, '+')"
                  ><v-icon>
                    mdi-plus
                  </v-icon></v-btn
                >
              </v-col>
              <v-col cols="12" md="7">
                <v-btn
                  v-if="
                    selected_disc.discount_upd &&
                      selected_disc.discount_upd.discount_kind == 'fixed'
                  "
                  class="ml-0 ml-md-4 mt-2"
                  tile
                  small
                  dense
                  outlined
                  color="warning"
                  @click="changeDiscount(selected_disc, ind)"
                >
                  Изменить скидку
                </v-btn>
                <v-btn
                  class="ml-2 ml-md-4 mt-2"
                  tile
                  small
                  dense
                  outlined
                  color="red lighten-1"
                  @click="clearDiscount(selected_disc, ind)"
                >
                  <v-icon left> mdi-minus </v-icon>
                  Удалить скидку
                </v-btn>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Оплата форма -->
    <v-row
      v-for="(payment_in, index) in payments"
      :key="'payment_' + payment_in.id"
    >
      <payment
        :ref="'payment_' + index"
        :payment="payment_in"
        :remove="removePayment"
        :save="save"
        :index="index"
        :disabledBtn="disabledBtn"
      />
    </v-row>
    <v-row>
      <v-col cols="12" sm="7">
        <v-btn class="ml-4" tile outlined color="primary" @click="addPayment">
          <v-icon left> mdi-plus </v-icon>
          Добавить платёж
        </v-btn>
      </v-col>
      <v-col cols="12" sm="5">
        <v-btn
          class="ml-4 mb-3"
          tile
          outlined
          color="primary"
          @click="exportExcel"
          :disabled="loadingExcel"
          :loading="loadingExcel"
        >
          Счет на оплату EXCEL
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay :absolute="true" :opacity="0.7" :value="overlay">
      <v-progress-circular :size="70" :width="7" color="amber" indeterminate />
    </v-overlay>
  </div>
</template>

<script>
import Payment from "./Payment";
import toast from "@/plugins/toast";
import moment from "moment";
import user from "../../../../store/auth/index";
import Vue from "vue";
import { mapGetters } from "vuex";
import { ServiceApiUrls } from "@/services/accommodationRequests/service.api.js";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";

export default {
  name: "Step4",
  components: {
    Payment
  },
  data() {
    return {
      disabledBtn: false,
      has_return: false,
      selected_discounts: [],
      promcodeSearch: "",
      selectedDiscount: null,
      overlay: true,
      isPromocode: "discount",
      discountItems: [],
      payments: [],
      paymentInfo: {
        calculation_type: "tariff",
        days: 13,
        accommodation: 0,
        early_check_in: 0,
        late_check_out: 0,
        additional_place: 0,
        total: 0,
        services: 0
      },
      payment_template: {
        payment_type: "payment",
        quantity: 0,
        comment: ""
      },
      loadingExcel: false,
      foodTypes: {
        breakfast: "Завтрак",
        lunch: "Обед",
        supper: "Ужин",
        all: "Трехразовое питание"
      }
    };
  },
  computed: {
    ...mapGetters(["getSteady"]),
    myEl() {
      return this.$store.getters.getEl();
    }
  },
  watch: {
    myEl() {
      if (this.myEl === 5) {
        this.loadDiscount();
      } else {
        this.selected_discounts = [];
      }
    }
  },
  methods: {
    async exportExcel() {
      try {
        this.loadingExcel = true;
        let res = (
          await window.axios({
            method: "get",
            url: `/api/v2/booking/${
              this.getSteady().is_group ? "invoice/group" : "invoice"
            }/${
              this.getSteady()[
                this.getSteady().is_group ? "group_parent" : "id"
              ]
            }/`,
            responseType: "blob",
            headers: {
              "Content-Type":
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
          })
        ).data;
        console.log(res);
        var blob = new Blob([res], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment"
        });
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(blob, "invoice.xlsx");
        } else {
          if (window.innerHeight < 500 || window.innerWidth < 500) {
            var ios =
              !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
            var isSafari =
              navigator.vendor &&
              navigator.vendor.indexOf("Apple") > -1 &&
              navigator.userAgent &&
              navigator.userAgent.indexOf("CriOS") == -1 &&
              navigator.userAgent.indexOf("FxiOS") == -1;
            if (ios && !isSafari) {
              toast.error("Вы можете скачать счет на оплату в Safari");
            } else {
              var reader = new FileReader();
              reader.fileName = "invoice.xlsx";
              var out = new File([res], "invoice.xlsx", {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment; filename='invoice.xlsx'"
              });
              reader.onload = function(e) {
                window.location.href = reader.result;
              };
              reader.readAsDataURL(out);
            }
          } else {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "invoice.xlsx");
            if (typeof link.download === "undefined") {
              link.setAttribute("target", "_blank");
            }
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            }, 100);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingExcel = false;
      }
    },
    // получение комнаты для расчета группового питания
    getRoomFood(id) {
      return this.getSteady().food_list.filter((i) => i.booking === id);
    },
    changeDiscount(item, index) {
      // не работает
      console.log(item);
    },
    // изменить кол-во скидок уже приобретенных
    changeRealDiscoutQuantity(index, type) {
      let value;
      if (type == "+")
        value = this.getSteady().discount_list[index].quantity + 1;
      else value = this.getSteady().discount_list[index].quantity - 1;
      delete this.getSteady().discount_list[index].quantity;
      this.$set(this.getSteady().discount_list[index], "quantity", value);
    },
    // изменить кол-во скидок
    changeDiscoutQuantity(type) {
      let value;
      if (type == "+") value = this.selectedDiscount.value + 1;
      else value = this.selectedDiscount.value - 1;
      delete this.selectedDiscount.value;
      this.$set(this.selectedDiscount, "value", value);
    },
    handleChangeDiscount() {
      this.selectedDiscount.value = 1;
    },
    handleChange() {
      this.$store.commit("SET_UPDATED_STEADY", 1);
    },
    // удалить скидку
    async clearDiscount(discount, ind) {
      try {
        let id;
        if (discount?.discount_upd?.id) id = discount.discount_upd.id;
        if (discount.id) id = discount.id;
        if (this.getSteady().is_group)
          await BookingApiUrls.deleteGroupDiscount(id);
        else await BookingApiUrls.deleteDiscount(id);
        toast.success("Успешно удалено!");
        this.handleChange();
        this.getSteady().discount_list.splice(ind, 1);
        let arr = [...this.getSteady().discount_list];
        this.getSteady().discount_list = [];
        this.getSteady().discount_list = [...arr];
        this.getBookPrice();
        let resp = await BookingApiUrls.getBooking(this.getSteady().id);
        this.$store.commit("SET_STEADY", resp.data);
      } catch (e) {}
    },
    getPromo() {
      // не работает
      let start = moment(Number(this.getSteady().check_in) * 1000).format(
        "YYYY-MM-DD"
      );
      let end = moment(Number(this.getSteady().check_out) * 1000).format(
        "YYYY-MM-DD"
      );
      return;
      window.axios
        .get(
          `/api/discount/find/${user.getters.getCompany}?start=${start}&end=${end}&promocode=${this.promcodeSearch}`
        )
        .then(resp => {
          this.selectedDiscount = resp.data;
          if (this.selectedDiscount.discount_kind === "percent") {
            this.paymentInfo.total -=
              (this.paymentInfo.total * this.selectedDiscount.quantity) / 100;
          } else {
            this.paymentInfo.total -= this.selectedDiscount.quantity;
          }
        });
    },
    // добавить скидку
    async calculateDiscount() {
      this.disabledBtn = true;
      let arr = {};
      if (this.selectedDiscount) {
        arr = {
          quantity: this.selectedDiscount.value,
          booking: this.getSteady().id,
          discount: this.selectedDiscount.id,
          name: this.selectedDiscount.name,
          discount_upd: {}
        };
        this.getSteady().discount_list = this.getSteady().discount_list || [];
        this.getSteady().discount_list.push(arr);
      } else if (this.promcodeSearch.length > 0) {
        toast.error("Нет функционала по промокодам!");
        return;
      }
      try {
        if (this.selectedDiscount && !this.selectedDiscount.id) {
          toast.error("Выберите скидку!");
          this.disabledBtn = false;
          this.getSteady().discount_list.splice(
            this.getSteady().discount_list.length - 1,
            1
          );
          return;
        }
        if (this.getSteady().discount_list.length == 0) {
          toast.error("Выберите скидку!");
          this.disabledBtn = false;
          this.getSteady().discount_list.splice(
            this.getSteady().discount_list.length - 1,
            1
          );
          return;
        }
        if (this.getSteady().is_group) {
          arr.booking = this.getSteady().group_parent;
          await BookingApiUrls.addGroupDiscount(arr);
        } else await BookingApiUrls.addDiscount(arr);
        this.getSteady().discount_list.splice(
          this.getSteady().discount_list.length - 1,
          1
        );
        this.$store.commit("SET_CHECK_DELETE", false);
        toast.success("Успешно добавлено!");
        this.selectedDiscount = null;
        this.promcodeSearch = "";
        this.handleChange();
        let resp = await BookingApiUrls.getBooking(this.getSteady().id);
        this.getSteady().discount_list = resp.data.discount_list;
        this.getBookPrice();
      } catch (e) {
        this.getSteady().discount_list.splice(
          this.getSteady().discount_list.length - 1,
          1
        );
      } finally {
        this.disabledBtn = false;
      }
    },
    getPaid(data) {
      let total = 0;
      if (data.payments != undefined) {
        data.payments.map(el => {
          if (el.payment_type == "payment") {
            total += el.quantity;
          } else {
            total -= el.quantity;
          }
        });
        return total;
      }
    },
    discountText: item =>
      item.id
        ? item.name +
          " - " +
          item.quantity +
          " " +
          (item.discount_kind === "percent" ? "%" : "тг")
        : item.name,
    async loadDiscount() {
      let resp = await ServiceApiUrls.getDiscounts();
      this.getBookPrice();
      this.discountItems = [
        {
          id: null,
          name: "Не выбран"
        }
      ];
      this.discountItems.push(...resp.results);
      if (this.getSteady().discount) {
        this.isPromocode = "discount";
      }
    },
    async getBookPrice() {
      this.getSteady().discount_list = this.getSteady().discount_list || [];
      const discount = [...this.getSteady().discount_list];
      discount.forEach(e => {
        if (e.discount.id) {
          e.discount_upd = e.discount;
          e.discount = e.discount.id;
        }
      });
      let services_list, check_in, check_out;
      let task_list = [];
      if (this.getSteady().is_group && this.getSteady().task_list) {
        this.getSteady().task_list.forEach(e => {
          if (e.title)
            task_list.push({
              ...e,
              responsible: e.responsible.id ? e.responsible.id : e.responsible // бывает responsible = { object | number }
            });
        });
      }
      if (this.getSteady().is_group)
        services_list =
          this.getSteady().group_calculation &&
          this.getSteady().group_calculation.services
            ? this.getSteady().group_calculation.services
            : [];
      else {
        services_list =
          this.getSteady().calculated_by &&
          this.getSteady().calculated_by.services
            ? this.getSteady().calculated_by.services
            : [];
        // check_in изменить формат
        check_in = moment(+this.getSteady().check_in * 1000).format(
          "YYYY-MM-DD HH:mm"
        );
        check_out = moment(+this.getSteady().check_out * 1000).format(
          "YYYY-MM-DD HH:mm"
        );
      }
      services_list.forEach(e => {
        e.service = e.service || e.id;
      });
      let body = {
        ...this.getSteady(),
        check_in: check_in,
        check_out: check_out,
        service_list: services_list,
        discount_list: discount,
        operation_type: this.getSteady().calculation_type
      };
      body.food_list = body.food_list.map((i) => {
        return {
          ...i,
          tariff: i.tariff.id
        }
      });
      let response;
      if (this.getSteady().is_group) {
        let child_list = [];
        this.getSteady().categories.forEach(e => {
          e.child_list.forEach(j => {
            child_list.push({
              ...j,
              group_parent: this.getSteady().group_parent
            });
            if (typeof child_list[child_list.length - 1].check_in == "number") {
              child_list[child_list.length - 1].check_in =
                moment(
                  new Date(+child_list[child_list.length - 1].check_in * 1000)
                ).format("YYYY-MM-DD") +
                " " +
                child_list[child_list.length - 1].hour_check_in;
            }
            if (
              typeof child_list[child_list.length - 1].check_out == "number"
            ) {
              child_list[child_list.length - 1].check_out =
                moment(
                  new Date(+child_list[child_list.length - 1].check_out * 1000)
                ).format("YYYY-MM-DD") +
                " " +
                child_list[child_list.length - 1].hour_check_out;
            }
          });
        });
        body.child_list = child_list;
        body.task_list = task_list

        if (this.getSteady().is_group) {
          body.group_calculation.food_list = this.getSteady().food_list;
        }

        console.log(body);
        response = await BookingApiUrls.getGroupPrice(
          this.getSteady().group_parent,
          body
        );
      } else {
        body.calculated_by.food_list = this.getSteady().food_list;
        response = await BookingApiUrls.getPrice(this.getSteady().id, body);
      }
      if (this.getSteady().is_group) {
        this.getSteady().group_calculation = response.data;
        this.paymentInfo = this.getSteady().group_calculation;
      } else {
        this.getSteady().calculated_by = response.data;
        this.paymentInfo = this.getSteady().calculated_by;
      }

      this.overlay = false;
      this.payments = [];
      this.payments = this.getSteady().payment_list;

      let return_amount = 0;
      let total = 0;
      let only_paid = 0;
      this.has_return = false;
      if (this.paymentInfo.payments != undefined) {
        console.log(this.paymentInfo.payments);
        this.paymentInfo.payments.map(el => {
          if (el.payment_type == "payment") {
            total += +el.quantity || 0;
            only_paid += +el.quantity || 0;
          } else {
            total -= +el.quantity || 0;
            return_amount += +el.quantity || 0;
            this.has_return = true;
          }
        });
      }
      this.paymentInfo["paid_amount"] = total;
      this.paymentInfo["return_amount"] = return_amount;
      this.paymentInfo["withheld"] = only_paid - return_amount;

      this.payments.forEach(element => {
        if (element.payment_type == "payment")
          this.paymentInfo.paid_amount += +element.value || 0;
        else this.paymentInfo.paid_amount += +element.value || 0;

        if (element.payment_type == "payment")
          this.paymentInfo.return_amount += +element.value || 0;
        else this.paymentInfo.return_amount += +element.value || 0;
      });
    },
    addPayment() {
      const id = this.payments.length + 1;
      let p_data = {};
      Object.keys(this.payment_template).map(el => {
        p_data[el] = this.payment_template[el];
      });
      this.payments.push(p_data);
      this.handleChange();
    },
    removePayment(index) {
      Vue.delete(this.payments, index);
      this.getBookPrice();
      this.handleChange();
    },
    // добавить оплату
    async save(index) {
      if (!this.payments[index].value && this.payments[index].value != 0) {
        toast.error("Введите сумму!");
        this.$refs["payment_" + index][0].my_btn_disabled = false;
        return;
      }
      if (!this.payments[index].payment_method) {
        toast.error("Выберите вид оплаты!");
        this.$refs["payment_" + index][0].my_btn_disabled = false;
        return;
      }
      let payment = {
        booking: this.getSteady().id,
        comment: this.payments[index].comment,
        payment_type: this.payments[index].payment_type,
        payment_method: this.payments[index].payment_method,
        value: this.payments[index].value
      };
      this.disabledBtn = true;
      try {
        let res;
        if (this.getSteady().is_group) {
          payment.booking = this.getSteady().group_parent;
          res = await BookingApiUrls.createGroupPayment(payment);
        } else {
          res = await BookingApiUrls.addPayment(payment);
        }
        if (res.data) {
          this.getSteady().booking_status = res.data.booking_status;
        }
        if (res.booking_status) {
          this.getSteady().booking_status = res.booking_status;
        }
        this.$store.commit("SET_CHECK_DELETE", false);
        let resp = await BookingApiUrls.getBooking(this.getSteady().id);
        this.getSteady().payment_list = resp.data.payment_list;
        this.getSteady().duty = resp.data.duty;
        this.getBookPrice();
        toast.success("Платеж оформлен!");

        if (this.payments[index].payment_type == "payment") {
          this.paymentInfo.paid_amount += this.payments[index].value;
        } else {
          this.paymentInfo.paid_amount -= this.payments[index].value;
        }

        if (this.payments[index].payment_type == "payment") {
          this.paymentInfo.return_amount += this.payments[index].value;
        } else {
          this.paymentInfo.return_amount -= this.payments[index].value;
        }

        this.disabledBtn = false;
        this.handleChange();
        if (this.$store.state.action !== "update") {
          this.$emit("closeBtn");
        }
      } catch (e) {
        this.disabledBtn = false;
      }
    }
  }
};
</script>

<style scoped>
.group_title,
.group_title_room {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #c5c5c5;
}
.group_title_room {
  color: #000000;
}
::v-deep .theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f4f4f4;
  border-radius: 0px;
  margin-bottom: 8px;
}
::v-deep .v-expansion-panel::before {
  box-shadow: none;
}
::v-deep
  .theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: #ff8413;
}
</style>
