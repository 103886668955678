<template>
  <div class="">
    <div ref="totalTable">
      <table style="width: 100%">
        <!-- Информация о брони -->
        <tr>
          <th class="table_header">
            Информация о брони
            <span>№ {{ getSteady().id }}</span>
          </th>
        </tr>
        <!-- Источник брони -->
        <tr>
          <td class="table_row">
            <span>{{ booking_type[getSteady().original_booking] }}</span>
          </td>
        </tr>
        <!-- Период -->
        <tr>
          <td class="table_row">
            Период:
            <span>
              {{ date_check_in }} -
              {{ date_check_out }}
            </span>
          </td>
        </tr>
        <!-- Категория -->
        <tr>
          <td class="table_row">
            Категория:
            <span>{{ getSteady().category_name }}</span>
          </td>
        </tr>
        <!-- Номер -->
        <tr>
          <td class="table_row">
            Номер:
            <span>{{ getSteady().room_name }}</span>
          </td>
        </tr>
        <!-- Ранний заезд -->
        <tr>
          <td class="table_row">
            Ранний заезд:
            <span>{{ getSteady().is_early ? "Да" : "Нет" }}</span>
          </td>
        </tr>
        <!-- Поздний заезд -->
        <tr>
          <td class="table_row">
            Поздний заезд:
            <span>{{ getSteady().is_late ? "Да" : "Нет" }}</span>
          </td>
        </tr>
        <!-- Статус -->
        <tr>
          <td class="table_row">
            Статус:
            <span>{{ statusItems[getSteady().booking_status] }}</span>
          </td>
        </tr>
        <!-- Доп. Место -->
        <tr>
          <td class="table_row">
            Доп. Место:
            <span>
              Взрослый {{ getSteady().adult_count }} <br />
              Детский {{ getSteady().child_count }}
            </span>
          </td>
        </tr>
        <!-- Данные клиента -->
        <tr>
          <th class="table_header">Данные клиента</th>
        </tr>
        <tr>
          <td class="table_row">
            Имя:
            <span
              v-if="
                getSteady().clients_list.length &&
                  getSteady().clients_list[0].first_name
              "
            >
              {{ getSteady().clients_list[0].first_name }}
              {{ getSteady().clients_list[0].last_name }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="table_row">
            Телефон:
            <span v-if="getSteady().clients_list.length">
              {{ getSteady().clients_list[0].phone_number }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="table_row">
            E-mail:
            <span v-if="getSteady().clients_list.length">
              {{ getSteady().clients_list[0].email }}
            </span>
          </td>
        </tr>
        <!-- Стоимость -->
        <tr>
          <th class="table_header">Стоимость</th>
        </tr>
        <tr>
          <td class="table_row">
            Тип расчета:
            <span>
              {{ paymentInfo.calculation_type }}
            </span>
          </td>
        </tr>
        <tr v-if="!getSteady().is_time">
          <td class="table_row">
            Суток:
            <span>
              {{ paymentInfo.days }}
            </span>
          </td>
        </tr>
        <tr v-if="getSteady().is_time">
          <td class="table_row">
            Часов:
            <span>
              {{ paymentInfo.days }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="table_row">
            За проживание:
            <span>
              {{ paymentInfo.price }}
            </span>
          </td>
        </tr>
        <tr v-if="!getSteady().is_time">
          <td class="table_row">
            Ранний заезд:
            <span>
              {{ paymentInfo.early_check_in }}
            </span>
          </td>
        </tr>
        <tr v-if="!getSteady().is_time">
          <td class="table_row">
            Поздний выезд:
            <span>
              {{ paymentInfo.late_check_out }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="table_row">
            Доп место:
            <span>
              {{ paymentInfo.adult_price + paymentInfo.child_price }}
            </span>
          </td>
        </tr>
        <tr v-if="!getSteady().is_time">
          <td class="table_row">
            Раннее бронирование:
            <span>
              {{ paymentInfo.advance_booking }}
            </span>
          </td>
        </tr>
        <!-- Услуги -->
        <tr v-if="paymentInfo.services.length">
          <td class="table_row">Услуги:</td>
        </tr>
        <tr v-if="paymentInfo.services.length">
          <td
            class="table_row service_row"
            v-for="(service, index) in paymentInfo.services"
            :key="index"
            style="padding-left: 20px"
          >
            {{ service.name }}
            <span>
              {{ service.price }}
            </span>
          </td>
        </tr>
        <!-- Питание -->
        <tr v-if="paymentInfo.food_list.length">
          <td class="table_row">Питание:</td>
        </tr>
        <tr v-if="paymentInfo.food_list.length">
          <td
            class="table_row service_row"
            v-for="(food, index) in paymentInfo.food_list"
            :key="index"
            style="padding-left: 20px"
          >
            {{ foodTypes[food.tariff.food_type] }}
            <span>
              {{ food.total }}
            </span>
          </td>
        </tr>
        <!-- ИТОГО -->
        <tr>
          <td class="table_row">
            <strong style="font-size: 16px">ИТОГО:</strong>
            <span>
              {{ paymentInfo.total }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="table_row">
            Оплачено:
            <span>
              {{ paymentInfo.total - paymentInfo.duty }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="table_row">
            Осталось оплатить:
            <span>
              {{ paymentInfo.duty }}
            </span>
          </td>
        </tr>
      </table>
    </div>
    <v-row>
      <v-col cols="12" md="3">
        <v-btn
          color="primary"
          style="margin-left: 10px;width:100%"
          dark
          @click="generateReport"
          :disabled="disabledEmail"
        >
          Скачать PDF
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" class="py-0 py-md-3">
        <v-btn
          color="primary"
          style="margin-left: 10px;width: 100%"
          @click="send_email"
          :disabled="disabledEmail"
          dark
        >
          Отправить на почту
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" class="py-0 py-md-3">
        <v-btn
          color="primary"
          style="margin-left: 10px;width: 100%"
          @click="downloadFoodTickets"
          :disabled="disabledEmail"
          dark
        >
          Сгенерировать талоны
        </v-btn>
      </v-col>
    </v-row>
    <img src="assets/images/kglogo.png" alt="" />
    <v-overlay :absolute="true" :opacity="0.7" :value="overlay">
      <v-progress-circular :size="70" :width="7" color="amber" indeterminate />
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import toast from "../../../plugins/toast";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";

export default {
  name: "Total",
  data: () => ({
    statusItems: {
      reserved: "Резерв",
      booked: "Бронь",
      check_in: "Заселен",
      check_out: "Выселен"
    },
    booking_type: {
      null: "easybook",
      "-1": "Виджет"
    },
    sendEmailData: {},
    emailLoading: false,
    header: "",
    footer: "",
    logo: "",
    overlay: true,
    disabledEmail: false,
    foodTypes: {
      breakfast: "Завтрак",
      lunch: "Обед",
      supper: "Ужин",
      all: "Трехразовое питание"
    },
    paymentInfo: {
      calculation_type: "tariff",
      days: 13,
      accommodation: 0,
      early_check_in: 0,
      late_check_out: 0,
      additional_place: 0,
      food_list: [],
      total: 0,
      services: 0
    }
  }),
  watch: {
    myEl() {
      if (this.myEl === 7) {
        this.getBookPrice();
      }
    }
  },
  computed: {
    ...mapGetters(["getSteady"]),
    date_check_in() {
      if (this.getSteady().is_time) {
        return moment(Number(this.getSteady().check_in) * 1000).format(
          "DD-MM-YYYY HH:mm"
        );
      } else {
        return moment(Number(this.getSteady().check_in) * 1000).format(
          "DD-MM-YYYY"
        );
      }
    },
    date_check_out() {
      if (this.getSteady().is_time) {
        return moment(Number(this.getSteady().check_out) * 1000).format(
          "DD-MM-YYYY HH:mm"
        );
      } else {
        return moment(Number(this.getSteady().check_out) * 1000).format(
          "DD-MM-YYYY"
        );
      }
    },
    myEl() {
      return this.$store.getters.getEl();
    }
  },
  methods: {
    // отправить на почту email
    async send_email() {
      if (this.$store.state.updated_steady > 0) {
        toast.error("Сохраните изменения!");
        return;
      }
      if (!this.getSteady().clients_list[0].email) {
        toast.error("Введите email и сохраните!");
        return;
      }
      this.disabledEmail = true;
      this.emailLoading = true;
      try {
        if (this.getSteady().is_group)
          await BookingApiUrls.sendGroupEmail(this.getSteady().group_parent);
        else await BookingApiUrls.sendEmail(this.getSteady().id);
        toast.success("Отправлено!");
      } finally {
        this.disabledEmail = false;
        this.emailLoading = false;
      }
    },
    // скачать pdf
    async generateReport() {
      if (this.$store.state.updated_steady > 0) {
        toast.error("Сохраните изменения!");
        return;
      }
      this.overlay = true;
      let resp;
      try {
        if (this.getSteady().is_group)
          resp = await BookingApiUrls.getGroupTicket(
            this.getSteady().group_parent
          );
        else resp = await BookingApiUrls.getTicket(this.getSteady().id);
        var link = document.createElement("a");
        link.download = "booking.pdf";
        link.target = "_blank";
        let ticket = resp.ticket;
        if (ticket && !ticket.includes("https")) {
          ticket = ticket.replace("http", "https");
        }
        link.href = ticket;
        console.log(link.href);
        document.body.appendChild(link);
        setTimeout(function() {
          link.click();
          document.body.removeChild(link);
        }, 500);
      } finally {
        this.overlay = false;
      }
    },
    // fetch цену брони
    async getBookPrice() {
      this.getSteady().discount_list = this.getSteady().discount_list || [];
      const discount = [...this.getSteady().discount_list];
      if (discount) {
        discount.forEach(e => {
          if (e.discount.id) {
            e.discount_upd = e.discount;
            e.discount = e.discount.id;
          }
        });
      }
      let services_list, check_in, check_out;
      let task_list = [];
      if (this.getSteady().is_group && this.getSteady().task_list) {
        this.getSteady().task_list.forEach(e => {
          if (e.title)
            task_list.push({
              ...e,
              responsible: e.responsible.id ? e.responsible.id : e.responsible
            });
        });
      }
      if (this.getSteady().is_group)
        services_list =
          this.getSteady().group_calculation &&
          this.getSteady().group_calculation.services
            ? this.getSteady().group_calculation.services
            : [];
      else {
        services_list =
          this.getSteady().calculated_by &&
          this.getSteady().calculated_by.services
            ? this.getSteady().calculated_by.services
            : [];
        // check_in изменить формат
        check_in = moment(+this.getSteady().check_in * 1000).format(
          "YYYY-MM-DD HH:mm"
        );
        check_out = moment(+this.getSteady().check_out * 1000).format(
          "YYYY-MM-DD HH:mm"
        );
      }
      services_list.forEach(e => {
        e.service = e.service || e.id;
      });
      let body = {
        ...this.getSteady(),
        check_in: check_in,
        check_out: check_out,
        service_list: services_list,
        discount_list: discount,
        operation_type: this.getSteady().calculation_type
      };
      body.food_list = this.getSteady().food_list.map(i => {
        return {
          ...i,
          tariff: i.tariff.id
        };
      });
      let response;
      if (this.getSteady().is_group) {
        let child_list = [];
        this.getSteady().categories.forEach(e => {
          e.child_list.forEach(j => {
            child_list.push({
              ...j,
              group_parent: this.getSteady().group_parent
            });
            child_list[child_list.length - 1].check_in =
              moment(
                new Date(+child_list[child_list.length - 1].check_in * 1000)
              ).format("YYYY-MM-DD") +
              " " +
              child_list[child_list.length - 1].hour_check_in;
            child_list[child_list.length - 1].check_out =
              moment(
                new Date(+child_list[child_list.length - 1].check_out * 1000)
              ).format("YYYY-MM-DD") +
              " " +
              child_list[child_list.length - 1].hour_check_out;
          });
        });
        body.child_list = child_list;
        body.task_list = task_list;
        response = await BookingApiUrls.getGroupPrice(
          this.getSteady().group_parent,
          body
        );
      } else {
        response = await BookingApiUrls.getPrice(this.getSteady().id, body);
      }
      this.paymentInfo = {
        ...response.data,
        duty: this.getSteady().duty
      };
      this.overlay = false;
    },
    // сгенерировать талоны
    async downloadFoodTickets() {
      this.disabledEmail = true;
      try {
        if (this.getSteady().is_group)
          await BookingApiUrls.generateGroupTalon(
            this.getSteady().group_parent
          );
        else await BookingApiUrls.generateSingleTalon(this.getSteady().id);
        toast.success("Отправлено!");
      } finally {
        this.disabledEmail = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: orange;
  font-size: 16px !important;
  font-weight: bold;
  padding: 3px 9px;
}
.table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 9px;
}
.table_row span {
  width: 66%;
  display: inline-block;
}
@media screen and (max-width: 500px) {
  .table_row span {
    width: 33%;
  }
}
.service_row span {
  width: 67%;
}
.orange {
  background: orange;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.title {
  font-size: 16px !important;
  font-weight: bold;
}
</style>
