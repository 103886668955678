const axios = window.axios
const url = '/api/v2/main/calendar/'
const urlHour = '/api/v2/main/calendar-time/'
const urlToday = '/api/v2/main/today/'
const urlLeisure = '/api/v2/main/calendar-leisure/'
const urlPrices = '/api/v2/main/leisure-price/'




export const CalendarApiUrls = {
    // calendar
    async getCalendar(params) {
        return (await axios.get(url, { params: params }));
    },
    // export calendar to excel
    async exportExcel(params){
        return (await axios.get('/api/v2/main/export-calendar/', { params: params, responseType: "blob",
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        } }));
    },

    // состояние на сегодня
    async getToday() {
        return (await axios.get(urlToday)).data;
    },

    // часовые брони
    async getHourCalendar(params) {
        return (await axios.get(urlHour, { params: params })).data;
    },


    // активный отдых календарь
    async getLeisureCalendar(id, params) {
        return (await axios.get(urlLeisure+id+'/', { params: params })).data;
    },
    // активный отдых prices
    async getLeisurePrices(params) {
        return (await axios.get(urlPrices, { params: params })).data;
    },
};
  