<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="model"
            :items="users"
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            item-text="full_name"
            item-value="id"
            label="Клиент"
            return-object
            clearable
            @change="handleChangeAutoComplete"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <div v-for="(client, index) in getSteady().clients_list" :key="index">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="client.first_name"
              @change="handleChange"
              label="Имя"
              :rules="firstRules"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="client.last_name"
              @change="handleChange"
              label="Фамилия"
              :rules="lastRules"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              @change="handleChange"
              v-model="client.middle_name"
              label="Отчество"
              :rules="middleRules"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="client.phone_number"
              @change="handleChange"
              label="Телефон"
              :rules="phoneRules"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="client.email"
              @change="handleChange"
              label="e-mail"
              :rules="emailRules"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="client.document_number"
              @change="handleChange"
              label="Паспорт"
              :rules="documentRules"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="client.gender"
              @change="handleChange"
              :items="genders"
              :rules="genderRules"
            />
          </v-col>
          <v-col cols="12" md="8">
            <v-textarea
              v-model="client.comment"
              @change="handleChange"
              label="Комментарии"
              rows="1"
              :rules="commentRules"
            />
          </v-col>
        </v-row>
        <v-btn
          v-if="getSteady().clients_list.length != 1"
          class="mb-5"
          tile
          outlined
          color="red lighten-1"
          @click="deleteClient(index)"
        >
          <v-icon left>
            mdi-minus
          </v-icon>
          Удалить клиента
        </v-btn>
        <v-divider class="mb-2" />
      </div>
    </v-form>
    <v-row>
      <v-col>
        <v-btn title outlined color="primary" @click="addClient">
          <v-icon left>
            mdi-plus
          </v-icon>
          Добавить
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import toast from "../../../../plugins/toast";
import { ClientApiUrls } from "@/services/accommodationRequests/client.api.js";

export default {
  name: "Step2",
  props: {
    categories: Array,
    required_fields: Array
  },
  data() {
    return {
      model: null,
      isLoading: false,
      firsName: [v => !!v || "Обязательное поле для заполнения "],
      genders: [
        { text: "Муж.", value: "male" },
        { text: "Жен.", value: "female" }
      ],
      users: [],
      search: null,
      firstRules: [
        v => {
          if (this.required_fields.includes("first_name"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ],
      lastRules: [
        v => {
          if (this.required_fields.includes("last_name"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ],
      middleRules: [
        v => {
          if (this.required_fields.includes("middle_name"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ],
      phoneRules: [
        v => {
          if (this.required_fields.includes("phone_number"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ],
      emailRules: [
        v => {
          if (this.required_fields.includes("email"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ],
      genderRules: [
        v => {
          if (this.required_fields.includes("gender"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ],
      commentRules: [
        v => {
          if (this.required_fields.includes("comment"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ],
      documentRules: [
        v => {
          if (this.required_fields.includes("document_number"))
            return !!v || "Обязательное поле для заполнения";
          else return true;
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getSteady"]),
    myEl() {
      return this.$store.getters.getEl();
    }
  },
  watch: {
    search() {
      this.loadUsers(this.search);
    },
    myEl() {
      if (
        this.myEl == 2 &&
        this.getSteady().clients_list &&
        this.getSteady().clients_list.length > 0
      )
        this.loadUsers(
          `${this.getSteady().clients_list[0].first_name} ${this.getSteady()
            .clients_list[0].last_name || ""} `
        );
      this.model = this.getSteady().clients_list[0];
    }
  },
  created() {
    this.search = null;
    this.loadUsers();
  },
  methods: {
    handleChange() {
      this.$store.commit("SET_UPDATED_STEADY", 1);
    },
    handleChangeAutoComplete() {
      if (this.model)
        this.getSteady().clients_list[0] = JSON.parse(
          JSON.stringify(this.model)
        );
      else this.getSteady().clients_list[0] = {};
    },
    async loadUsers(value = null) {
      if (value && value.length > 1) this.handleChange();
      let params = {
        "page[number]": 1,
        "page[size]": 10,
        search: value
      };
      let resp = await ClientApiUrls.getUsers(params);
      resp.results.forEach(e => {
        e.full_name =
          (e.first_name || "") +
          " " +
          (e.last_name || "") +
          " " +
          (e.middle_name || "");
      });
      this.users = resp.results;
    },
    validate() {
      this.$refs.form.validate();
    },
    deleteClient(index) {
      this.handleChange();
      this.getSteady().clients_list.splice(index, 1);
    },
    addClient() {
      let category = this.categories.filter(e => {
        return e.id == this.getSteady().category_id;
      });
      let total_cout =
        this.getSteady().child_count +
        this.getSteady().adult_count +
        category[0].place_number;
      if (total_cout > this.getSteady().clients_list.length) {
        this.handleChange();
        this.getSteady().clients_list.push({
          first_name: "",
          last_name: "",
          middle_name: "",
          phone_number: "",
          email: "",
          id_card: "",
          gender: "male",
          comment: "",
          document_number: ""
        });
      } else {
        toast.error("Недостаточно мест");
      }
    }
  }
};
</script>
